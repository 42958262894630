import { isEmpty, sortBy } from 'lodash';
import config from '../../config/config';
import { CARRIER_FAILED_TO_RESPOND_ERROR_MESSAGE, COMING_SOON, FAILED, HIPPO_LABEL, INACTIVE, INSURANCE_BUSINESS_TYPES, INSURANCE_PRODUCTS, PURCHASE_TYPE } from '../../constants';
import { api, ErrorStatus, HomeQuote, HomeQuotePending, HomeUpcomingCarrier, QuoteErrorType } from '../../graphql/generated';
import {
  refreshAutoQuotes,
  refreshCyberSecurityQuotes,
  refreshFloodQuotes,
  refreshHomeQuotes,
  refreshLifeQuotes,
  refreshUmbrellaQuotes,
} from '../../pages/redux/actions';
import store from '../../pages/redux/store';
import {
  checkPrefillErrorExistInErrorQuoteList,
  getIsLobQuotesLoading,
  getMappedQuotingCarriers,
  getMappedUpComingSoonCarriersList,
  getQuoteAndErrQuoteCount,
  prsV2ErrorCategoryMapping,
} from '../../utils';
import { LineOfBusiness } from '../enums';
import { isEnabledPRSv2Flow } from './common';
import { setHomeQuoteDetails } from '../redux/slices';
const { AUTO, CYBERSECURITY, FLOOD, HOMEWARRANTY, LIFE, PET, QUAKE, UMBRELLA } =
  INSURANCE_BUSINESS_TYPES;

export const ratePageTypes = {
  carousel: 'carousel',
  navigation: 'navigation',
};

export const quotePollingHandlerOnRefreshClick = () => {
  const { common } = store.getState();
  const selectedBusinessOption = common?.selectedBusinessOption?.toLowerCase() || '';
  if (
    isEmpty(selectedBusinessOption) ||
    common?.lobRefreshLoader ||
    getIsLobQuotesLoading(selectedBusinessOption)
  ) {
    return;
  }
  if (selectedBusinessOption === LineOfBusiness.HOME) {
    if (isEnabledPRSv2Flow()) {
      store.dispatch(setHomeQuoteDetails({ isRefetchHomePollQuote: true }));
    } else {
      store.dispatch(refreshHomeQuotes());
    }
  } else if (selectedBusinessOption === LineOfBusiness.AUTO) {
    store.dispatch(refreshAutoQuotes());
  } else if (selectedBusinessOption === LineOfBusiness.LIFE) {
    store.dispatch(refreshLifeQuotes());
  } else if (selectedBusinessOption === INSURANCE_PRODUCTS[7]) {
    store.dispatch(refreshCyberSecurityQuotes());
  } else if (selectedBusinessOption === LineOfBusiness.UMBRELLA) {
    store.dispatch(refreshUmbrellaQuotes());
  } else if (selectedBusinessOption === LineOfBusiness.FLOOD) {
    store.dispatch(refreshFloodQuotes());
  }
};

export const compareBusinessType = (currentQuoteTab: string = '', showApplicationEdit: boolean) => {
  const {
    cyberSecurityDetails,
    floodDetails,
    homeWarrantyDetails,
    lifeDetails,
    petDetails,
    quote,
    quakeDetails,
    umbrellaDetails,
  } = store.getState();
  const { quotesLoading, quoteErrorList, quoteList: autoQuoteList } = quote;
  const { data: { organization } = {} } = api.endpoints.organization.select()(store.getState());

  const {
    submitLoader,
    quoteList: floodQuoteList,
    quoteErrorList: floodQuoteErrorList,
  } = floodDetails;
  let loading = false;
  let quoteList: Array<any> = [];
  const displayWithErrors = !!organization?.displayErrorsOnUi;
  const carrierTimeoutMessageEnabled = organization?.carrierTimeoutMessageEnabled;
  let isRefreshButtonEnabled = carrierTimeoutMessageEnabled && showApplicationEdit;
  // for home this function will not be called
  const quoteDetails = getQuoteAndErrQuoteCount(currentQuoteTab);
  const isQuotePresentForLob = !!quoteDetails?.quoteCount || !!quoteDetails?.errorCount || false;
  switch (currentQuoteTab) {
    case FLOOD:
      loading = submitLoader;
      currentQuoteTab = INSURANCE_BUSINESS_TYPES.FLOOD;
      quoteList =
        !submitLoader && displayWithErrors
          ? [...floodQuoteList, ...floodQuoteErrorList]
          : floodQuoteList;
      quoteList = getMappedUpComingSoonCarriersList(
        quoteList,
        floodDetails?.upcomingCarrierList,
        loading,
        floodDetails?.quoteStatus
      );
      break;
    case AUTO:
      loading = quotesLoading;
      quoteList = displayWithErrors ? [...autoQuoteList, ...quoteErrorList] : autoQuoteList;
      if (!isEmpty(quote?.quotingCarriers) && isRefreshButtonEnabled && isQuotePresentForLob) {
        quoteList = getMappedQuotingCarriers(
          quote.quotingCarriers,
          autoQuoteList,
          quoteErrorList,
          displayWithErrors
        );
      }
      quoteList = getMappedUpComingSoonCarriersList(
        quoteList,
        quote?.upcomingCarrierList,
        loading,
        quote?.quoteStatus
      );
      break;
    case QUAKE:
      loading = quakeDetails?.quakeSubmitLoader;
      quoteList = quakeDetails?.quoteList;
      quoteList = getMappedUpComingSoonCarriersList(
        quoteList,
        quakeDetails?.upcomingCarrierList,
        loading,
        quakeDetails?.quoteStatus
      );
      break;
    case PET:
      loading = petDetails?.quoteListLoader || petDetails?.petBreedsDetailsLoader;
      quoteList = petDetails?.quoteList;
      quoteList = getMappedUpComingSoonCarriersList(
        quoteList,
        petDetails?.upcomingCarrierList,
        loading,
        petDetails?.quoteStatus
      );
      break;
    case HOMEWARRANTY:
      loading = homeWarrantyDetails?.quoteListLoader;
      quoteList = homeWarrantyDetails?.quoteList;
      quoteList = getMappedUpComingSoonCarriersList(
        quoteList,
        homeWarrantyDetails?.upcomingCarrierList,
        loading,
        homeWarrantyDetails?.quoteStatus
      );
      break;
    case CYBERSECURITY:
      let cyberSecurityQuoteList: Array<any> = !isEmpty(cyberSecurityDetails?.quoteList)
        ? [...cyberSecurityDetails.quoteList]
        : [];
      let cyberSecurityQuoteErrorList: Array<any> = !isEmpty(cyberSecurityDetails?.quoteErrorList)
        ? [...cyberSecurityDetails.quoteErrorList]
        : [];
      loading = cyberSecurityDetails?.quoteListLoader;
      quoteList = displayWithErrors
        ? [...cyberSecurityQuoteList, ...cyberSecurityQuoteErrorList]
        : cyberSecurityDetails?.quoteList;
      if (
        !isEmpty(cyberSecurityDetails?.quotingCarriers) &&
        isRefreshButtonEnabled &&
        isQuotePresentForLob
      ) {
        quoteList = getMappedQuotingCarriers(
          cyberSecurityDetails.quotingCarriers,
          cyberSecurityQuoteList,
          cyberSecurityQuoteErrorList,
          displayWithErrors
        );
      }
      quoteList = getMappedUpComingSoonCarriersList(
        quoteList,
        cyberSecurityDetails?.upcomingCarrierList,
        loading,
        cyberSecurityDetails?.quoteStatus
      );
      break;
    case LIFE:
      loading = lifeDetails?.quoteListLoader;
      quoteList = displayWithErrors
        ? [...lifeDetails?.quoteList, ...lifeDetails?.quoteErrorList]
        : lifeDetails?.quoteList;
      if (
        !isEmpty(lifeDetails?.quotingCarriers) &&
        isRefreshButtonEnabled &&
        isQuotePresentForLob
      ) {
        quoteList = getMappedQuotingCarriers(
          lifeDetails.quotingCarriers,
          lifeDetails?.quoteList,
          lifeDetails?.quoteErrorList,
          displayWithErrors
        );
      }
      quoteList = getMappedUpComingSoonCarriersList(
        quoteList,
        lifeDetails?.upcomingCarrierList,
        loading,
        lifeDetails?.quoteStatus
      );
      break;
    case UMBRELLA:
      loading = umbrellaDetails?.quoteListLoader;
      quoteList = displayWithErrors
        ? [...umbrellaDetails?.quoteList, ...umbrellaDetails?.quoteErrorList]
        : umbrellaDetails?.quoteList;
      if (
        !isEmpty(umbrellaDetails?.quotingCarriers) &&
        isRefreshButtonEnabled &&
        isQuotePresentForLob
      ) {
        quoteList = getMappedQuotingCarriers(
          umbrellaDetails.quotingCarriers,
          umbrellaDetails?.quoteList,
          umbrellaDetails?.quoteErrorList,
          displayWithErrors
        );
      }
      quoteList = getMappedUpComingSoonCarriersList(
        quoteList,
        umbrellaDetails?.upcomingCarrierList,
        loading,
        umbrellaDetails?.quoteStatus
      );
      break;
  }
  return { loading, quoteList, currentQuoteTab, displayWithErrors };
};

export const loadQuotesOnPrsV2RatePage = (newAppointments: any) => {
  return (
    Object.keys(newAppointments).includes('HOME') ||
    Object.keys(newAppointments).includes('QUAKE') ||
    Object.keys(newAppointments).includes('HOMEWARRANTY') ||
    Object.keys(newAppointments).includes('CYBERSECURITY')
  );
};

export const getPrsv2ErrorCategory = (quote: HomeQuote): string => {
  const errorCategory = quote?.error?.errorCategory;
  if (
    config.enableCarrierCategorization &&
    errorCategory &&
    errorCategory in prsV2ErrorCategoryMapping
  ) {
    return prsV2ErrorCategoryMapping[errorCategory];
  }
  return '';
};

export const removeDuplicatesByCarrierId = (quotes: HomeQuote[]): HomeQuote[] => {
  const seen = new Set<string>();
  return quotes.filter((quote: HomeQuote) => {
    const carrierId = quote?.carrierId?.toString();
    if (carrierId && !seen.has(carrierId)) {
      seen.add(carrierId);
      return true;
    }
    return false;
  });
};

export const getSortedHomeQuoteList = (
  quotes: HomeQuote[] = [],
  pendingQuotes: HomeQuotePending[] = [],
  displayErrorsOnUi: boolean = false,
  isPolling: boolean = false
): HomeQuote[] => {
  const successQuotes = quotes.filter(quote => !!quote?.data);
  const errorQuotes = quotes.filter(quote => !!quote?.error);
  const upcomingCarrier = getPendingAndNotIntegratedCarrierList();

  let quotesList = successQuotes;

  if (displayErrorsOnUi && !isPolling) {
    quotesList = [...successQuotes, ...upcomingCarrier, ...errorQuotes];
  }

  // Sort quotes by price and carrier name
  const sortedList = sortBy(removeDuplicatesByCarrierId(quotesList), [
    (item: HomeQuote) => item?.data?.price || Infinity, // Sort by price, handle undefined
    (item: HomeQuote) => (item?.carrierName === HIPPO_LABEL && item?.data ? 0 : 1), // Sort by carrierName
  ]);

  return mapQuotingCarrierDetails(sortedList, pendingQuotes, displayErrorsOnUi, isPolling);
};

export const mapQuotingCarrierDetails = (
  quoteList: HomeQuote[] = [],
  pendingQuotes: HomeQuotePending[] = [],
  displayErrorsOnUi: boolean = false,
  isPolling: boolean = false
): any => {
  if (
    isEmpty(pendingQuotes) ||
    !displayErrorsOnUi ||
    isPolling ||
    checkPrefillErrorExistInErrorQuoteList(quoteList)
  ) {
    return quoteList;
  }
  const tempQuotingCarriers = pendingQuotes.filter(
    pendingQuote =>
      !quoteList.some(quote => quote.carrierId?.toString() === pendingQuote.carrierId.toString())
  );
  return [
    ...quoteList,
    ...tempQuotingCarriers.map(quote => ({
      carrierName: quote.carrierName,
      carrierId: quote.carrierId,
      tooltip: quote.carrierName,
      logoUrl: quote.logoUrl,
      error: {
        errorStatus: FAILED,
        errorType: null,
        errorCategory: null,
        statusText: CARRIER_FAILED_TO_RESPOND_ERROR_MESSAGE,
      },
      data: null,
    })),
  ];
};

export const getPendingAndNotIntegratedCarrierList = (): any => {
  const {
    homeQuoteDetails: { upcomingCarriers },
  } = store.getState();
  if (isEmpty(upcomingCarriers)) {
    return [];
  }
  return upcomingCarriers.map((item: HomeUpcomingCarrier) => ({
    carrierId: item?.carrierId,
    carrierName: item?.carrierName,
    tooltip: null,
    logoUrl: item.logoUrl,
    agentUrl: item.agentUrl,
    isUpcoming: true,
    error: {
      errorStatus: ErrorStatus.Failed,
      errorType: QuoteErrorType.Carrier,
      errorCategory: null,
      statusText: !item.isActive ? INACTIVE : COMING_SOON,
    },
    data: null,
  }));
};

export const getPurchaseType = (quote: HomeQuote) => {
  return quote?.carrierName?.toLowerCase() === HIPPO_LABEL?.toLowerCase()
    ? PURCHASE_TYPE.PURCHASE_ONLINE
    : PURCHASE_TYPE.BTC;
};

import { isEmpty, isUndefined, sortBy, startCase, values } from 'lodash';
import moment from 'moment';
import config from '../config/config';
import {
  ALLOW_DOWNLOAD_QUOTE,
  HIPPO_LABEL,
  HEAP_LABELS,
  INSURANCE_BUSINESS_TYPES,
  INSURANCE_PRODUCTS,
  LOB_PRODUCTS_MAPPING,
  PROPERTY_INFO,
  REQUEST_BIND_PREFILL_INFORMATION,
  YEAR_CONSTRUCTED_ERROR_MESSAGE,
  QUOTING_STEPS_PAGES_NAME,
  CARRIER,
  PREMIUM,
  INVALID,
  INSURANCE_PRODUCTS_MAPPING,
  FAILED,
  CYBER_SECURITY,
  HOME_WARRANTY,
  EARTH_QUAKE_LOB,
} from '../constants';
import { InputFieldDetails } from '../pages/components/property-info/type';
import { FormDataType } from '../pages/custom-hooks';
import { EMPTY, ERROR_MESSAGE } from '../pages/custom-hooks/useFormHandler/utils';
import {
  getCarrierAndPremiumRank,
  getIsPropertyInfoEnabledDetails,
  getLobHerdId,
  getNestedTernaryData,
  getQuoteAndErrQuoteCount,
  getQuoteStatus,
  errorStatusList,
  validateDate,
  propertyTypeMapping,
  dateFormat,
} from './index';
import {
  downloadAutoQuoteDetails,
  downloadQuoteDetails,
  setFloodDetails,
  setHomeState,
  setQuoteCompleted,
  storeCyberSecurityQuoteDetails,
  storeHomeWarrantyQuoteList,
  storeLifeQuoteDetails,
  storePetDetails,
  storeQuakeDetails,
  storeUmbrellaQuoteDetails,
} from '../pages/redux/actions';
import store from '../pages/redux/store';
import {
  CyberSecurityState,
  UpcomingCarriersListType,
} from '../pages/redux/reducers/cyber-security';
import { ObjectEntity } from '../pages/redux/reducers/helper';
import { AMS_FIELD_KEYS, INCOMPLETE, IN_PROGRESS_STATUS } from '../constants/common';
import { HomeState, PartnerConfig } from '../pages/redux/reducers/home';
import { QuoteState } from '../pages/redux/reducers/quote';
import { UmbrellaDetailsState } from '../pages/redux/reducers/umbrella-details';
import { QuakeDetailsState } from '../pages/redux/reducers/quake-details';
import { PetDetailsState } from '../pages/redux/reducers/pet-details';
import { HomeWarrantyState } from '../pages/redux/reducers/home-warranty';
import { LifeDetailsState } from '../pages/redux/reducers/life';
import { QuoteErrorCategory } from '../graphql/generated';
const { BROKER_CODE, DEPARTMENT_CODE, GROUP_CODE } = AMS_FIELD_KEYS;
const { PROPERTY_INFO_DETAILS, OTHER_DETAILS, COVERAGE_DETAILS } = PROPERTY_INFO;
const propertyInfoFormFields = {
  ...PROPERTY_INFO_DETAILS.INPUT_FIELDS,
  ...OTHER_DETAILS.INPUT_FIELDS,
  ...COVERAGE_DETAILS.INPUT_FIELDS,
};

export const errorCategoryMapping = {
  applicationError: 'application-error',
  addressableCarrierError: 'addressable-carrier-error',
  nonAddressableCarrierError: 'non-addressable-carrier-error',
  agentAddressable: 'agent-addressable',
  agentAddressablePropertyData: 'agent-addressable-property-data',
  agentAddressableCoverages: 'agent-addressable-coverages',
  applicationErrorExternal: 'application-error-external',
  applicationErrorInternal: 'application-error-internal',
  underwritingExposureManagement: 'underwriting-exposure-management',
  underwritingPropertyRisk: 'underwriting-property-risk',
};

export const quoteErrorCategoryClassNameMapping: Record<string, string> = {
  application_error: errorCategoryMapping.applicationError,
  addressable_carrier_error: errorCategoryMapping.addressableCarrierError,
  non_addressable_carrier_error: errorCategoryMapping.nonAddressableCarrierError,
  'Agent Addressable': errorCategoryMapping.agentAddressable,
  'Agent Addressable - Property Data': errorCategoryMapping.agentAddressablePropertyData,
  'Agent Addressable - Coverages': errorCategoryMapping.agentAddressableCoverages,
  'Application Error - External': errorCategoryMapping.applicationErrorExternal,
  'Application Error - Internal': errorCategoryMapping.applicationErrorInternal,
  'Underwriting - Exposure Management': errorCategoryMapping.underwritingExposureManagement,
  'Underwriting - Property Risk': errorCategoryMapping.underwritingPropertyRisk,
};

export const errorCategories = {
  applicationError: 'application_error',
  addressableCarrierError: 'addressable_carrier_error',
  nonAddressableCarrierError: 'non_addressable_carrier_error',
  agentAddressable: 'Agent Addressable',
  agentAddressablePropertyData: 'Agent Addressable - Property Data',
  agentAddressableCoverages: 'Agent Addressable - Coverages',
  applicationErrorExternal: 'Application Error - External',
  applicationErrorInternal: 'Application Error - Internal',
  underwritingExposureManagement: 'Underwriting - Exposure Management',
  underwritingPropertyRisk: 'Underwriting - Property Risk'
};

export const quoteCarrierErrorTypes: Array<string> = Object.values(errorCategories);

export const prsV2ErrorCategoryMapping = {
  [QuoteErrorCategory.ApplicationError]: errorCategories.applicationError,
  [QuoteErrorCategory.AddressableCarrierError]: errorCategories.addressableCarrierError,
  [QuoteErrorCategory.NonAddressableCarrierError]: errorCategories.nonAddressableCarrierError,
  [QuoteErrorCategory.AgentAddressable]: errorCategories.agentAddressable,
  [QuoteErrorCategory.ApplicationErrorExternal]: errorCategories.applicationErrorExternal,
  [QuoteErrorCategory.ApplicationErrorInternal]: errorCategories.applicationErrorInternal,
  [QuoteErrorCategory.UnderwritingExposureManagement]:
    errorCategories.underwritingExposureManagement,
  [QuoteErrorCategory.UnderwritingPropertyRisk]: errorCategories.underwritingPropertyRisk,
};

export const carrierCategoryListToOpenErrorDetails: Array<string> = [
  'addressable_carrier_error',
  'Agent Addressable',
  'Agent Addressable - Property Data',
  'Agent Addressable - Coverages',
];

interface QuoteErrorCategoryDetailsTypes {
  [key: string]: {
    label: string;
    displayMessage1: string;
    displayMessage2: string;
  };
}

export const quoteErrorCategoryDetails: QuoteErrorCategoryDetailsTypes = {
  application_error: {
    label: 'An error has occurred',
    displayMessage1: `We're sorry, but an error has occurred which prevents returning a rate for this carrier. The engineering team has been notified.`,
    displayMessage2: `Please use the carrier's portal to attempt this quote.`,
  },
  addressable_carrier_error: {
    label: 'Attention needed',
    displayMessage1: `We're sorry, but the carrier was unable to provide a rate as submitted. Please alter the quote information accordingly and resubmit if desired.`,
    displayMessage2: `Please see the details below for further information.`,
  },
  non_addressable_carrier_error: {
    label: 'Unable to quote',
    displayMessage1: `We're sorry, but the carrier is unable to return a rate for this risk.`,
    displayMessage2: `Please use the carrier's portal to requote or contact the carrier if you believe this is in error.`,
  },
  'Agent Addressable': {
    label: 'Attention needed',
    displayMessage1: `We're sorry, but the carrier was unable to provide a quote with the information as submitted.`,
    displayMessage2: `Please alter the information accordingly and resubmit if desired. See details below.`,
  },
  'Agent Addressable - Property Data': {
    label: 'Attention needed',
    displayMessage1: `We're sorry, but the carrier was unable to provide a quote with the information as submitted.`,
    displayMessage2: `Please alter the information accordingly and resubmit if desired. See details below.`,
  },
  'Agent Addressable - Coverages': {
    label: 'Attention needed',
    displayMessage1: `We're sorry, but the carrier was unable to provide a quote with the information as submitted.`,
    displayMessage2: `Please alter the information accordingly and resubmit if desired. See details below.`,
  },
  'Application Error - External': {
    label: 'An error has occurred',
    displayMessage1: `We're sorry, but an application error has occurred which prevents returning a quote from this carrier. The engineering team has been notified.`,
    displayMessage2: `Please use this carrier's portal to attempt this quote. See details below.`,
  },
  'Application Error - Internal': {
    label: 'An error has occurred',
    displayMessage1: `We're sorry, but an application error has occurred which prevents returning a quote from this carrier. The engineering team has been notified.`,
    displayMessage2: `Please use this carrier's portal to attempt this quote. See details below.`,
  },
  'Underwriting - Exposure Management': {
    label: 'Underwriting Declination',
    displayMessage1: `We're sorry, but the carrier has declined to quote on this request for risk exposure reasons.`,
    displayMessage2: `Please use the carrier's portal to attempt to retrieve the quote or contact the carrier if you believe this is in error. See details below.`,
  },
  'Underwriting - Property Risk': {
    label: 'Underwriting Declination',
    displayMessage1: `We're sorry, but the carrier has declined to quote on this request for risk exposure reasons.`,
    displayMessage2: `Please use the carrier's portal to attempt to retrieve the quote or contact the carrier if you believe this is in error. See details below.`,
  },
};

export const extractPremiumDetails = (quoteList: any) => {
  quoteList.forEach((carrierOfQuote: any, index: number) => {
    if (carrierOfQuote?.premiumDetails?.length) {
      const premiumDetails = sortBy(carrierOfQuote.premiumDetails, ['premium']);
      quoteList[index].price = premiumDetails[0]?.premium;
    }
  });
  return quoteList;
};

export const getSortedQuoteList = (quoteList: Array<any>, lob?: string) => {
  let list = quoteList || [];
  if (lob === INSURANCE_BUSINESS_TYPES.FLOOD) {
    list = extractPremiumDetails(list);
  }
  list = sortBy(list, (item: any) => {
    if (item?.price) {
      return parseFloat(item?.price);
    }
  });
  list = sortBy(list, [
    (item: any) => (item?.carrier_name === HIPPO_LABEL || item?.name === HIPPO_LABEL ? 0 : 1),
  ]);
  return list;
};

export const getQuoteCarrierErrorCategory = (quote: any) => {
  return !!config?.enableCarrierCategorization &&
    !isEmpty(quote?.errorCategory) &&
    quoteCarrierErrorTypes?.includes(quote?.errorCategory)
    ? quote?.errorCategory
    : '';
};

export const setAllValuesToString = (
  record: Record<string, string | boolean | number | undefined | null>
) => {
  const tempRecord: Record<string, string> = {};
  Object.keys(record).forEach(key => {
    tempRecord[key] = record[key]?.toString() || '';
  });
  return tempRecord;
};

export const positiveIntegerRegex = /^[1-9][0-9]*$/;
export const integerRegex = /^[0-9]*$/;

export const getThemeType = () => {
  const { home } = store.getState();
  const { pconfig } = home;
  return !!pconfig?.dale_config?.branding_on_dale_details?.template_name
    ? pconfig?.dale_config?.branding_on_dale_details?.template_name
    : config.hippo.template_name;
};

export const getOptionsLabelByValue = (
  options: {
    label: string;
    value: string;
  }[],
  value: string
) => {
  let [selectedOption] = options.filter(
    option => option.value?.toLowerCase() === value.toLowerCase()
  );
  if (!!selectedOption && !isEmpty(selectedOption)) {
    return selectedOption.label;
  }
  return EMPTY;
};

export const checkForOrganizationSettings = (key: string) => {
  const { home, common } = store.getState();
  return (
    home?.pconfig?.dale_config?.[key as keyof PartnerConfig['dale_config']] ||
    common?.pconfig?.dale_config?.[key as keyof PartnerConfig['dale_config']]
  );
};

export const getIsDownloadQuoteEnabled = (lob: string) => {
  const { HOME_QUOTE, AUTO_QUOTE } = ALLOW_DOWNLOAD_QUOTE;
  lob = lob?.toLowerCase() || '';
  if (lob === LOB_PRODUCTS_MAPPING.home) {
    return !!checkForOrganizationSettings(HOME_QUOTE.KEY);
  } else if (lob === LOB_PRODUCTS_MAPPING.auto) {
    return !!checkForOrganizationSettings(AUTO_QUOTE.KEY);
  } else {
    return false;
  }
};

export const validateYearBuiltAndRoofConstructed = (
  formData: FormDataType,
  errors: FormDataType,
  isPropertyInfoForm: boolean = true
) => {
  const inputFields: Record<string, InputFieldDetails> = isPropertyInfoForm
    ? propertyInfoFormFields
    : REQUEST_BIND_PREFILL_INFORMATION.INPUT_FIELDS;
  const yearBuilt = inputFields?.YEAR_BUILT?.KEY;
  const roofConstructed = inputFields?.ROOF_CONSTRUCTED_OR_REPLACED?.KEY;
  const updatedElectric = inputFields?.UPDATED_ELECTRIC?.KEY;
  const updatedHeating = inputFields?.UPDATED_HEATING?.KEY;
  const updatedPlumbing = inputFields?.UPDATED_PLUMBING?.KEY;
  [yearBuilt, roofConstructed, updatedElectric, updatedHeating, updatedPlumbing].forEach(key => {
    const value = formData[key];
    if (!value) return;
    const { MAX_VALUE, MIN_VALUE } = Object.values(inputFields).filter(
      fieldDetails => fieldDetails.KEY === key
    )[0];
    if (MIN_VALUE && MAX_VALUE) {
      const minYear = new Date(MIN_VALUE).getFullYear();
      const maxYear = new Date(MAX_VALUE).getFullYear();
      if (Number(value) > maxYear || Number(value) < minYear) {
        errors[key] = ERROR_MESSAGE.INVALID;
        return;
      }
      /** Check Roof Constructed/Replaced can't be less than Year Built */
      if (
        formData[yearBuilt] &&
        formData[roofConstructed] &&
        Number(formData[yearBuilt]) > Number(formData[roofConstructed])
      ) {
        errors[roofConstructed] = YEAR_CONSTRUCTED_ERROR_MESSAGE;
      }
    }
  });
  errors.purchaseDate = validateDate(
    formData?.purchaseDate,
    inputFields?.PURCHASE_DATE?.MIN_VALUE || '',
    inputFields?.PURCHASE_DATE?.MAX_VALUE || '',
    false
  );
  return { ...errors };
};

export const isNoQuotesAvailableForLob = (displayWithErrors: boolean, lob: string) => {
  const { home } = store.getState();
  lob = !isEmpty(lob) ? lob?.toLowerCase() : '';
  const quoteDetails: any = getQuoteAndErrQuoteCount(lob);
  const { auto, life, pet, umbrella } = LOB_PRODUCTS_MAPPING;
  if ([auto, life, pet, umbrella].includes(lob)) {
    return !isEmpty(getLobHerdId(lob)) &&
      !displayWithErrors &&
      quoteDetails?.errorCount &&
      !quoteDetails?.quoteCount
      ? true
      : false;
  }
  if (lob === INSURANCE_PRODUCTS[0]) {
    return (!isEmpty(home?.herd_id) &&
      !quoteDetails?.quoteCount &&
      !displayWithErrors &&
      (!getIsPropertyInfoEnabledDetails() ||
        (getIsPropertyInfoEnabledDetails() && !!quoteDetails?.errorCount))) ||
      !!home?.isNoQuoteAvailable
      ? true
      : false;
  }
  return (
    !isEmpty(getLobHerdId(lob)) &&
    (!displayWithErrors
      ? !quoteDetails?.quoteCount
      : !quoteDetails?.errorCount && !quoteDetails?.quoteCount)
  );
};

export const getFloodPlansList = (premiumDetails: Array<AppComponents.FloodPremiumDetails>) => {
  const floodPlans: AppComponents.FloodPlan = {};
  premiumDetails.length &&
    premiumDetails.forEach((premiumDetail: AppComponents.FloodPremiumDetails) => {
      if (premiumDetail?.plan) {
        floodPlans[premiumDetail.plan] = premiumDetail.tier_details;
      }
    });
  return floodPlans;
};

export const trackMoreDetails = (type: string, quote: any) => {
  let quoteDetails = getQuoteAndErrQuoteCount(type);
  let premiumDetails = getCarrierAndPremiumRank(type, quote?.carrier_id, quote?.price);
  window.heap.track(QUOTING_STEPS_PAGES_NAME?.RATES_MORE_DETAILS, {
    [HEAP_LABELS?.PRODUCT]: !isEmpty(type) ? startCase(type?.toLowerCase()) : '',
    [CARRIER]: quote?.carrierName || quote?.name || quote?.carrier_name || '',
    [PREMIUM]: quote?.price || '',
    [`${HEAP_LABELS?.NO_OF_QUOTES} ${
      !isEmpty(type) ? `For ${startCase(type?.toLowerCase())} Product` : ''
    }`]: quoteDetails?.quoteCount || 0,
    [HEAP_LABELS?.PRODUCT_COUNT]: quoteDetails?.productCount,
    [HEAP_LABELS?.PREMIUM_RANK]: premiumDetails?.premiumRank || 0,
    [HEAP_LABELS?.RESULT_RANK]: premiumDetails?.quoteRank || 0,
  });
};

export const getDownLoadQuoteDetails = (payload: AppRedux.ActionPayload, lob: string) => {
  if (lob === LOB_PRODUCTS_MAPPING.home) {
    store.dispatch(downloadQuoteDetails(payload));
  }
  if (lob === LOB_PRODUCTS_MAPPING.auto) {
    store.dispatch(downloadAutoQuoteDetails(payload));
  }
};

export const getSalesforceCustomerUUID = (amsDetails: any) => {
  return !isEmpty(amsDetails?.salesforceCustomerUUID) ? amsDetails?.salesforceCustomerUUID : '';
};

export const uuidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const validateUUID = (formValue: string) => {
  return !isEmpty(formValue) && !uuidRegex.test(formValue) ? INVALID : '';
};

export const getInsuranceProductListToLowerCase = (list: Array<string>) => {
  if (isEmpty(list)) {
    return [];
  }
  return list.map(item =>
    INSURANCE_PRODUCTS_MAPPING[item?.toUpperCase() as keyof typeof INSURANCE_PRODUCTS_MAPPING]?.toLowerCase()
  );
};

export const getInsuranceProducts = (producerDetails?: any, lobDetails?: any) => {
  if (!isEmpty(producerDetails)) {
    return getInsuranceProductListToLowerCase(producerDetails?.availableLobs);
  }
  return !isEmpty(lobDetails)
    ? getInsuranceProductListToLowerCase(Object.keys(lobDetails))
    : INSURANCE_PRODUCTS;
};

export const getIsAdmittedOrNonAdmittedCarrier = (quote: any) => {
  if (
    !config?.enableAdmittedOrNonAdmittedCarrier ||
    isUndefined(quote.isAdmitted) ||
    errorStatusList.includes(getQuoteStatus(quote))
  ) {
    return undefined;
  }
  return !!quote?.isAdmitted;
};

export const sortAdmittedAndNonAdmittedCarrier = (quoteList: Array<any>) => {
  if (isEmpty(quoteList)) {
    return [];
  }
  const successQuoteList: Array<any> = [];
  const nonAmittedQuoteList: Array<any> = [];
  const errorQuoteList: Array<any> = [];
  const comingSoonCarrierList: Array<any> =
    quoteList.filter(
      item => !item?.isAvailableForQuoting && !isUndefined(item.isAvailableForQuoting)
    ) || [];
  const inactiveCarrierList: Array<any> =
    quoteList.filter(item => !!item?.isAvailableForQuoting && !item?.isActive) || [];
  quoteList.forEach(item => {
    const isErrorQuote = !!errorStatusList.includes(getQuoteStatus(item));
    if (isErrorQuote && !item?.isUpcomingCarrier) {
      errorQuoteList.push(item);
    }
    if (!isErrorQuote) {
      const isAdmitted: boolean | undefined = getIsAdmittedOrNonAdmittedCarrier(item);
      if (isAdmitted === undefined || !!isAdmitted) {
        successQuoteList.push(item);
      } else {
        nonAmittedQuoteList.push(item);
      }
    }
  });

  return [
    ...getSortedQuoteList(successQuoteList),
    ...getSortedQuoteList(nonAmittedQuoteList),
    ...comingSoonCarrierList,
    ...inactiveCarrierList,
    ...errorQuoteList,
  ];
};

export const getAMSCarrierDetails = (lob: string, carrierId: any) => {
  const { common } = store.getState();
  lob = lob === LOB_PRODUCTS_MAPPING.quake ? EARTH_QUAKE_LOB : lob;
  if (isEmpty(common?.AMSLOBDetails?.carrierList) || isEmpty(lob)) {
    return null;
  }
  let carrierList: Array<any> = common?.AMSLOBDetails?.carrierList[lob] || [];
  let carrierDetails: any = carrierList.find(
    (item: any) => item?.carrierId?.toString() === carrierId?.toString()
  );
  return carrierDetails || null;
};

export const isShowCarrierRiskConsentCheckbox = (carrierDetails: any) => {
  return (
    !!carrierDetails &&
    !isUndefined(carrierDetails.isAdmitted) &&
    !carrierDetails.isAdmitted &&
    !!config?.enableAdmittedOrNonAdmittedCarrier
  );
};

export const shouldApplicantHasPreviousAddress = (): boolean => {
  const {
    partner: { appointments },
  } = store.getState();
  return Object.keys(appointments).includes(INSURANCE_PRODUCTS[1].toUpperCase());
};

export const parseError = (error: string | undefined | null): string => (!!error ? error : '');

export const isEmptyValues = (object: Record<any, any>): boolean =>
  values(Object.values(object)).every(isEmpty);

export const isShowPrefillErrorOrSuccessInPropertyInfoTab = () => {
  if (!config?.enablePrefillBanner) {
    return false;
  }
  return true;
};

export const getAppointmentList = () => {
  const {
    partner: { appointments },
  } = store.getState();
  return !isEmpty(appointments) ? Object.keys(appointments) : [];
};

export const getIsLobIncludesInAppointments = (lob: string) => {
  lob = lob?.toUpperCase() || '';
  const appointmentList = getAppointmentList();
  return appointmentList.includes(lob);
};

export const getLobToOverridePropertyInfo = () => {
  if (getIsLobIncludesInAppointments(LOB_PRODUCTS_MAPPING.flood)) {
    return LOB_PRODUCTS_MAPPING.flood;
  }
  return getIsLobIncludesInAppointments(LOB_PRODUCTS_MAPPING.home) ? LOB_PRODUCTS_MAPPING.home : '';
};

export const getCarrierName = (quote: any) => quote.carrier_name || quote.name || quote?.Carrier;

export const getMappedUpComingSoonCarriersList = (
  quoteList: Array<any>,
  upcomingCarrierList: Array<UpcomingCarriersListType> = [],
  loading: boolean,
  status: string = ''
) => {
  if (!!loading || [IN_PROGRESS_STATUS, INCOMPLETE].includes(status?.toLowerCase())) {
    return [...quoteList];
  }
  return [
    ...quoteList,
    ...upcomingCarrierList.map(item => ({
      Status: startCase(FAILED),
      Carrier: item.carrierName,
      tooltip: '',
      logo_url: item.carrierLogo,
      CarrierId: item.carrierId,
      StatusText: item?.statusText || '',
      isUpcomingCarrier: true,
      purchase_type: item?.purchaseType || '',
      agent_url: item?.agentUrl || '',
      isActive: item.isActive,
      isAvailableForQuoting: item.isAvailableForQuoting,
    })),
  ];
};

export const setViewQuoteDetails = (quotesByLob: any) => {
  if (quotesByLob?.home) {
    store.dispatch(
      setHomeState({
        upcomingCarrierList: getNestedTernaryData(
          !isEmpty(quotesByLob?.home?.upcomingCarrierList),
          quotesByLob?.home?.upcomingCarrierList,
          []
        ),
        quoteStatus: quotesByLob?.home?.status || '',
      })
    );
  }
  if (quotesByLob?.auto) {
    store.dispatch(
      setQuoteCompleted({
        upcomingCarrierList: getNestedTernaryData(
          !isEmpty(quotesByLob?.auto?.upcomingCarrierList),
          quotesByLob?.auto?.upcomingCarrierList,
          []
        ),
        quoteStatus: quotesByLob?.auto?.status || '',
      })
    );
  }
  if (quotesByLob?.flood) {
    store.dispatch(
      setFloodDetails({
        upcomingCarrierList: getNestedTernaryData(
          !isEmpty(quotesByLob?.flood?.upcomingCarrierList),
          quotesByLob?.flood?.upcomingCarrierList,
          []
        ),
        quoteStatus: quotesByLob?.flood?.status || '',
      })
    );
  }
  if (quotesByLob?.quake) {
    store.dispatch(
      storeQuakeDetails({
        upcomingCarrierList: getNestedTernaryData(
          !isEmpty(quotesByLob?.quake?.upcomingCarrierList),
          quotesByLob?.quake?.upcomingCarrierList,
          []
        ),
        quoteStatus: quotesByLob?.quake?.status || '',
      })
    );
  }
  if (quotesByLob?.umbrella) {
    store.dispatch(
      storeUmbrellaQuoteDetails({
        upcomingCarrierList: getNestedTernaryData(
          !isEmpty(quotesByLob?.umbrella?.upcomingCarrierList),
          quotesByLob?.umbrella?.upcomingCarrierList,
          []
        ),
        quoteStatus: quotesByLob?.umbrella?.status || '',
      })
    );
  }
  if (quotesByLob?.pet) {
    store.dispatch(
      storePetDetails({
        upcomingCarrierList: getNestedTernaryData(
          !isEmpty(quotesByLob?.pet?.upcomingCarrierList),
          quotesByLob?.pet?.upcomingCarrierList,
          []
        ),
        quoteStatus: quotesByLob?.pet?.status || '',
      })
    );
  }
  if (quotesByLob?.life) {
    store.dispatch(
      storeLifeQuoteDetails({
        upcomingCarrierList: getNestedTernaryData(
          !isEmpty(quotesByLob?.life?.upcomingCarrierList),
          quotesByLob?.life?.upcomingCarrierList,
          []
        ),
        quoteStatus: quotesByLob?.life?.status || '',
      })
    );
  }
  if (quotesByLob?.[CYBER_SECURITY]) {
    store.dispatch(
      storeCyberSecurityQuoteDetails({
        upcomingCarrierList: getNestedTernaryData(
          !isEmpty(quotesByLob?.[CYBER_SECURITY]?.upcomingCarrierList),
          quotesByLob?.[CYBER_SECURITY]?.upcomingCarrierList,
          []
        ),
        quoteStatus: quotesByLob?.[CYBER_SECURITY]?.status || '',
      })
    );
  }
  if (quotesByLob?.[HOME_WARRANTY]) {
    store.dispatch(
      storeHomeWarrantyQuoteList({
        upcomingCarrierList: getNestedTernaryData(
          !isEmpty(quotesByLob?.[HOME_WARRANTY]?.upcomingCarrierList),
          quotesByLob?.[HOME_WARRANTY]?.upcomingCarrierList,
          []
        ),
        quoteStatus: quotesByLob?.[HOME_WARRANTY]?.status || '',
      })
    );
  }
};

export const getLobDetails = (lob: string, key: string) => {
  if (!lob || !key) return null;
  let details = null;
  const {
    home,
    quote,
    quakeDetails: earthquakeDetails,
    petDetails,
    floodDetails,
    homeWarrantyDetails,
    lifeDetails,
    cyberSecurityDetails,
    umbrellaDetails,
  } = store.getState();
  lob =
    lob?.toLowerCase() === EARTH_QUAKE_LOB ? INSURANCE_PRODUCTS_MAPPING.QUAKE : lob?.toUpperCase();
  switch (lob) {
    case INSURANCE_PRODUCTS_MAPPING.HOME:
      details = home?.[key as keyof HomeState];
      break;
    case INSURANCE_PRODUCTS_MAPPING.AUTO:
      details = quote?.[key as keyof QuoteState];
      break;
    case INSURANCE_PRODUCTS_MAPPING.FLOOD:
      details = floodDetails?.[key as keyof FloodDetailsState];
      break;
    case INSURANCE_PRODUCTS_MAPPING.QUAKE:
      details = earthquakeDetails?.[key as keyof QuakeDetailsState];
      break;
    case INSURANCE_PRODUCTS_MAPPING.PET:
      details = petDetails?.[key as keyof PetDetailsState];
      break;
    case INSURANCE_PRODUCTS_MAPPING['HOME-WARRANTY']:
      details = homeWarrantyDetails?.[key as keyof HomeWarrantyState];
      break;
    case INSURANCE_PRODUCTS_MAPPING.LIFE:
      details = lifeDetails?.[key as keyof LifeDetailsState];
      break;
    case INSURANCE_PRODUCTS_MAPPING['CYBER-SECURITY']:
      details = cyberSecurityDetails?.[key as keyof CyberSecurityState];
      break;
    case INSURANCE_PRODUCTS_MAPPING.UMBRELLA:
      details = umbrellaDetails?.[key as keyof UmbrellaDetailsState];
      break;
  }
  return details;
};

export const getAMSHeaderDefaults = (orgCode: string) => {
  const amsHeaderDefaults = config?.amsHeaderDefaultSettings;
  if (isEmpty(amsHeaderDefaults)) {
    return;
  }
  return amsHeaderDefaults?.[orgCode] ?? amsHeaderDefaults?.default ?? null;
};

export const getDetailsFromArrayUsingKey = (
  list: Array<ObjectEntity> = [],
  key: string = '',
  value: string = ''
) => {
  return !isEmpty(key) && !isEmpty(value) && !isEmpty(list)
    ? list.find(item => item[key] === value)
    : {};
};

export const getDefaultValueFormAmsDropDown = (list: Array<ObjectEntity>, key: string) => {
  return !isEmpty(key) && list?.length === 1 ? list[0][key] : '';
};

export const getEmployeeDetailsFromArray = (list: Array<ObjectEntity> = [], value: string = '') => {
  return !isEmpty(value) && !isEmpty(list)
    ? list.find(
        item =>
          `${item?.FirstName} ${item?.LastName}`?.trim()?.toLowerCase() ===
          value?.trim()?.toLowerCase()
      )
    : {};
};

export const getAms360DefaultValues = (details: any) => {
  const amsHeaderDefaults = details?.amsHeaderDefaults || null;
  if (!amsHeaderDefaults) {
    return;
  }
  const brokerDefaultValue =
    getDefaultValueFormAmsDropDown(details?.brokerOptions || details?.brokerList, BROKER_CODE) ||
    '';
  return {
    amsHeaderDefaults: details?.amsHeaderDefaults || null,
    executive:
      getEmployeeDetailsFromArray(details?.executivesList, amsHeaderDefaults?.executive)
        ?.EmployeeCode || '',
    representative:
      getEmployeeDetailsFromArray(details?.representativesList, amsHeaderDefaults?.representative)
        ?.EmployeeCode || '',
    employee:
      getEmployeeDetailsFromArray(details?.employeeList, amsHeaderDefaults?.employee)
        ?.EmployeeCode || '',
    broker: brokerDefaultValue,
    group:
      getDefaultValueFormAmsDropDown(details?.groupOptions || details?.groupList, GROUP_CODE) || '',
    department:
      getDefaultValueFormAmsDropDown(
        details?.departmentOptions || details?.departmentList,
        DEPARTMENT_CODE
      ) || '',
    assistedBy: brokerDefaultValue,
  };
};

export const openUrlInNewTab = (url: string) => {
  window.open(url, '_blank');
};

export const clearSelectedQuoteOnPropertyInfoEdit = (lob: string) => {
  const selectedQuoteList = sessionStorage.selectedQuoteList
    ? JSON.parse(sessionStorage.selectedQuoteList)
    : [];
  if (!isEmpty(selectedQuoteList) && !isEmpty(lob)) {
    let selectedLob = lob === EARTH_QUAKE_LOB ? INSURANCE_PRODUCTS[3] : lob;
    const selectedQuotes = selectedQuoteList.filter((item: any) => {
      let newData = '';
      if (!!item?.type && selectedLob && item?.type.toLowerCase() !== selectedLob.toLowerCase()) {
        newData = item?.quote;
      }
      return newData;
    });
    sessionStorage.selectedQuoteList = JSON.stringify(selectedQuotes);
  }
};

export const covertStringToArray = (item: string = '') => {
  return !isEmpty(item) ? item?.split(', ') : [];
};

export const getIsCondoProduct = (type: string = ''): boolean => {
  return type === propertyTypeMapping.ho6;
};

export const getDateFromToday = (days: number) => {
  return moment().add(days, 'days').format(dateFormat);
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PropertyOtherDetails } from './types/property-info';
import {
  PropertyCoverage,
  PropertyData,
  PropertyDeductible,
  ResidenceType,
} from '../../../graphql/generated';
import { PrefillStatus } from '../../enums';

interface PropertyInfoDetailsState {
  propertyInfo: PropertyData;
  coverageDetails: PropertyCoverage;
  deductibleDetails: PropertyDeductible;
  propertyOtherDetails: PropertyOtherDetails;
  createPropertyDataLoader: boolean;
  getPropertyInfoLoader: boolean;
  updatePropertyInfoLoader: boolean;
  updatePropertyError: string;
  isPropertyInfoForFloodUpdated: boolean;
  isPropertyInfoForHomeUpdated: boolean;
  isPropertyInfoForEarthQuakeUpdate: boolean;
  createPropertyInfoError: string;
  prefillStatus: PrefillStatus;
  noOfRetries: number;
  isShowPropertyInfoForHomeLob: boolean;
  isShowPropertyInfoForFloodLob: boolean;
  showPropertyDataLooseWarning: boolean;
}

const initialState: PropertyInfoDetailsState = {
  propertyInfo: {
    propertyId: '',
    revision: 0,
    addressId: '',
    building: null,
    homeowner: null,
    location: null,
    rebuildingCost: null,
    risk: null,
    site: null,
    address: null,
  },
  coverageDetails: {},
  deductibleDetails: {},
  propertyOtherDetails: {
    previousPolicyCancelled: 'No',
    residenceType: ResidenceType.Primary,
    personalPropertyReplacementCost: 'Yes',
  },
  createPropertyDataLoader: false,
  getPropertyInfoLoader: false,
  updatePropertyInfoLoader: false,
  updatePropertyError: '',
  isPropertyInfoForFloodUpdated: false,
  isPropertyInfoForHomeUpdated: false,
  isPropertyInfoForEarthQuakeUpdate: false,
  createPropertyInfoError: '',
  prefillStatus: PrefillStatus.SUCCESS,
  noOfRetries: 0,
  isShowPropertyInfoForHomeLob: true,
  isShowPropertyInfoForFloodLob: true,
  showPropertyDataLooseWarning: false,
};

const propertyInfoSlice = createSlice({
  name: 'propertyInfoDetails',
  initialState,
  reducers: {
    setPropertyDetails(state, action: PayloadAction<PropertyData>) {
      Object.assign(state.propertyInfo, action.payload);
    },
    setCreatePropertyInfoLoader(state, action: PayloadAction<boolean>) {
      state.createPropertyDataLoader = action.payload;
    },
    setGetPropertyInfoLoader(state, action: PayloadAction<boolean>) {
      state.getPropertyInfoLoader = action.payload;
    },
    setUpdatePropertyInfoLoader(state, action: PayloadAction<boolean>) {
      state.updatePropertyInfoLoader = action.payload;
    },
    setIsPropertyInfoForFloodUpdated(state, action: PayloadAction<boolean>) {
      state.isPropertyInfoForFloodUpdated = action.payload;
    },
    setIsPropertyInfoForHomeUpdated(state, action: PayloadAction<boolean>) {
      state.isPropertyInfoForHomeUpdated = action.payload;
    },
    setIsPropertyInfoForEarthQuakeUpdate(state, action: PayloadAction<boolean>) {
      state.isPropertyInfoForEarthQuakeUpdate = action.payload;
    },
    setIsShowPropertyInfoForHomeLob(state, action: PayloadAction<boolean>) {
      state.isShowPropertyInfoForHomeLob = action.payload;
    },
    setIsShowPropertyInfoForFloodLob(state, action: PayloadAction<boolean>) {
      state.isShowPropertyInfoForFloodLob = action.payload;
    },
    setNoOfRetries(state) {
      state.noOfRetries += 1;
    },
    setPrefillStatus(state, action: PayloadAction<PrefillStatus>) {
      state.prefillStatus = action.payload;
    },
    setCreatePropertyInfoError(state, action: PayloadAction<string>) {
      state.createPropertyInfoError = action.payload;
    },
    setCoverageDetails(state, action: PayloadAction<PropertyCoverage>) {
      state.coverageDetails = {
        ...state.coverageDetails,
        ...action.payload,
      };
    },
    setPropertyOtherDetails(state, action: PayloadAction<PropertyOtherDetails>) {
      state.propertyOtherDetails = {
        ...state.propertyOtherDetails,
        ...action.payload,
      };
    },
    setDeductibleDetails(state, action: PayloadAction<PropertyDeductible>) {
      state.deductibleDetails = {
        ...state.deductibleDetails,
        ...action.payload,
      };
    },
    setUpdatePropertyError(state, action: PayloadAction<string>) {
      state.updatePropertyError = action.payload;
    },
    setPropertyId(state, action: PayloadAction<string>) {
      state.propertyInfo.propertyId = action.payload;
    },
    setShowPropertyDataLooseWarning(state, action: PayloadAction<boolean>) {
      state.showPropertyDataLooseWarning = action.payload;
    },
  },
});

export const {
  setCoverageDetails,
  setCreatePropertyInfoError,
  setCreatePropertyInfoLoader,
  setDeductibleDetails,
  setGetPropertyInfoLoader,
  setIsPropertyInfoForEarthQuakeUpdate,
  setIsPropertyInfoForFloodUpdated,
  setIsShowPropertyInfoForHomeLob,
  setIsShowPropertyInfoForFloodLob,
  setIsPropertyInfoForHomeUpdated,
  setPrefillStatus,
  setPropertyDetails,
  setPropertyId,
  setPropertyOtherDetails,
  setUpdatePropertyError,
  setUpdatePropertyInfoLoader,
  setShowPropertyDataLooseWarning,
} = propertyInfoSlice.actions;

export default propertyInfoSlice;

import moment from 'moment';
import { v4 } from 'uuid';
import {
  LineOfBusiness,
  MaritalStatus,
  MutationCreateHomeQuoteRequestArgs,
  MutationUpdateHomeQuoteRequestArgs,
  ProductName,
  QuoteRequestMethod,
  QuoteRequestType,
  ResidenceType,
} from '../../../../../graphql/generated';
import { gatorToBffEducation } from './education.mapper';
import { invert, isEmpty } from 'lodash';
import { ApplicantDetailsFormDetailsType } from '../../../../pages/applicant/types';
import store from '../../../../../pages/redux/store';
import { gatorToBffOccupation } from './occupation.mapper';
import { gatorToBffIndustry } from './industry.mapper';
import { DEFAULT_PAGE_TRACKING, INSURANCE_BUSINESS_TYPES } from '../../../../../constants';
import { getLobHerdId } from '../../../../../utils';
const { AUTO, CYBERSECURITY, FLOOD, HOME, HOMEWARRANTY, LIFE, PET, QUAKE, UMBRELLA } =
  INSURANCE_BUSINESS_TYPES;

export const mapCreateHomeRequest = (
  applicantDetails: ApplicantDetailsFormDetailsType
): MutationCreateHomeQuoteRequestArgs => {
  const {
    auth: { userId, email },
    partner: { appointments, storedRequestId },
    propertyInfoDetails: { propertyInfo },
  } = store.getState();
  const { personalDetails, product } = applicantDetails;
  const selectedLob = Object.keys(appointments).map(
    (key: string) => selectedLobMapping[key] || LineOfBusiness.Home
  );
  return {
    input: {
      referenceId: storedRequestId ?? v4(),
      propertyId: propertyInfo?.propertyId ?? null,
      propertyRevision: propertyInfo?.revision ?? null,
      productName: product as ProductName,
      applicant: {
        firstName: personalDetails.first_name ?? null,
        middleName: !isEmpty(personalDetails?.middle_name?.trim())
          ? personalDetails.middle_name
          : null,
        lastName: personalDetails.last_name ?? null,
        dateOfBirth: personalDetails?.date_of_birth
          ? moment(personalDetails?.date_of_birth).utc().format()
          : null,
        phone: personalDetails.phone_number ?? null,
        email: personalDetails.email ?? null,
        education: gatorToBffEducation[personalDetails?.education],
        occupation: gatorToBffOccupation[personalDetails.occupation],
        industry: gatorToBffIndustry[personalDetails.industry],
        maritalStatus: MaritalStatus.Single,
      },
      quoteBy: {
        id: userId,
        name: email,
      },
      pageTracking: [
        {
          key: LineOfBusiness.Home,
          value: DEFAULT_PAGE_TRACKING.home,
        },
      ],
      quoteMethod: QuoteRequestMethod.Single,
      quoteType: QuoteRequestType.Consumer,
      residenceType: ResidenceType.Primary,
      selectedLob: selectedLob,
    },
  };
};

export const mapUpdateHomeRequest = (
  applicantDetails: ApplicantDetailsFormDetailsType
): MutationUpdateHomeQuoteRequestArgs => {
  const {
    partner: { appointments },
    propertyInfoDetails: { propertyInfo },
  } = store.getState();
  const { personalDetails, product } = applicantDetails;
  const selectedLob = Object.keys(appointments).map(
    (key: string) => selectedLobMapping[key] || LineOfBusiness.Home
  );
  const herdId = getLobHerdId(LineOfBusiness.Home);
  return {
    id: herdId,
    input: {
      propertyId: propertyInfo.propertyId,
      propertyRevision: propertyInfo.revision,
      productName: product as ProductName,
      pageTracking: [
        {
          key: LineOfBusiness.Home,
          value: DEFAULT_PAGE_TRACKING.home,
        },
      ],
      residenceType: ResidenceType.Primary,
      selectedLob: selectedLob,
      applicant: {
        firstName: personalDetails.first_name ?? null,
        middleName: !isEmpty(personalDetails?.middle_name?.trim())
          ? personalDetails.middle_name
          : null,
        lastName: personalDetails.last_name ?? null,
        dateOfBirth: personalDetails?.date_of_birth
          ? moment(personalDetails?.date_of_birth).utc().format()
          : null,
        phone: personalDetails.phone_number ?? null,
        email: personalDetails.email ?? null,
        education: gatorToBffEducation[personalDetails?.education],
        occupation: gatorToBffOccupation[personalDetails.occupation],
        industry: gatorToBffIndustry[personalDetails.industry],
      },
    },
  };
};

export const productMappingToState = {
  [ProductName.Dp1]: 'x',
  [ProductName.Dp3]: 'z',
  [ProductName.Ho3]: 'n',
  [ProductName.Ho4]: 'o',
  [ProductName.Ho5]: 'h',
  [ProductName.Ho6]: 'y',
  [ProductName.Pup]: '',
};

export const productMappingBffToState = invert(productMappingToState);

export const selectedLobMapping = {
  [AUTO]: LineOfBusiness.Auto,
  [CYBERSECURITY]: LineOfBusiness.Cybersecurity,
  [FLOOD]: LineOfBusiness.Flood,
  [HOME]: LineOfBusiness.Home,
  [HOMEWARRANTY]: LineOfBusiness.Homewarranty,
  [LIFE]: LineOfBusiness.Life,
  [PET]: LineOfBusiness.Pet,
  [QUAKE]: LineOfBusiness.Quake,
  [UMBRELLA]: LineOfBusiness.Umbrella,
};

import React, { FC } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DateFnsUtils from '@date-io/date-fns';
import PrefillInfoStatusBanner from './property-info-status-banner';
import { usePreventScrollOnFocus } from '../rate-page-common-component';
import {
  disabledApplicantFormField,
  getPropertyInfoSubmitButtonText,
  getSortedDropDownOptions,
  parseSelectedValueToOption,
  getPreferredDeductibleOptionsList,
} from '../../../utils';
import { InputFieldDetails, Options, TemplatePropes, TemplateStorePropes } from './type';
import {
  ASTERISK,
  BACK,
  BASEMENT,
  FALSE,
  INPUT_FIELD_TYPES,
  NONE,
  PROPERTY_INFO,
  UNFINISHED,
} from '../../../constants';
import './property-Info.scss';
import NumberFormatCustom from '../currency-mask';

const { PROPERTY_INFO_DETAILS, OTHER_DETAILS, COVERAGE_DETAILS } = PROPERTY_INFO;
const {
  BASEMENT_FINISH_TYPE,
  BASEMENT_FINISH_PERCENTAGE,
  FOUNDATION_TYPE,
  PURCHASE_DATE,
  ROOF_CONSTRUCTED_OR_REPLACED,
  SWIMMING_POOL_FENCED,
  SWIMMING_POOL_TYPE,
  UPDATED_ELECTRIC,
  UPDATED_HEATING,
  UPDATED_PLUMBING,
  YEAR_BUILT,
} = PROPERTY_INFO_DETAILS.INPUT_FIELDS;
const {
  DWELLING_LIMIT_COV_A,
  LOSS_OF_USE_LIMIT_OR_COV_D,
  OTHER_STRUCTURE_LIMIT_OR_COV_B,
  PERSONAL_PROPERTY_LIMIT_OR_COV_C,
  REPLACEMENT_COST,
  PREFERRED_DEDUCTIBLE,
  RECONSTRUCTION_COST_ESTIMATOR,
} = COVERAGE_DETAILS.INPUT_FIELDS;

const coverageFields = [
  DWELLING_LIMIT_COV_A.KEY,
  LOSS_OF_USE_LIMIT_OR_COV_D.KEY,
  OTHER_STRUCTURE_LIMIT_OR_COV_B.KEY,
  PERSONAL_PROPERTY_LIMIT_OR_COV_C.KEY,
  RECONSTRUCTION_COST_ESTIMATOR.KEY,
];

/** Creating drop-down options */
const createMenuItems = (options: Options[]) =>
  options.map((option: Options) => (
    <MenuItem key={option?.label} value={`${option?.value}~${option?.label}`}>
      {option?.label}
    </MenuItem>
  ));

const Template: FC<TemplatePropes> = ({
  applicant,
  formValues,
  formErrors,
  onFormSubmit,
  onChangeHandler,
  datePickerOnChangeHandler,
  onBackClick,
  getRequiredKeys,
  isShowRetryButton,
  onBlurPropertyDataFormField,
}) => {
  const requireFields = getRequiredKeys();
  const { propertyInfoLoader, savePropertyInfoloader } = applicant;
  const { foundationType, swimmingPool, basementFinishType } = formValues;
  let disabledFormField: boolean =
    !!disabledApplicantFormField()?.isDisableFormField ||
    !!propertyInfoLoader ||
    savePropertyInfoloader;
  const preventScrollOnFocus = usePreventScrollOnFocus();

  /** Common function to create TextField*/
  const drawTextField = (fieldDetails: InputFieldDetails, props: Record<string, any> = {}) => {
    const { LABEL, KEY, OPTIONS } = fieldDetails;
    let dropDownOptionList: Options[] = OPTIONS || [];
    const isHideBasementFinishTypeField: boolean =
      KEY === BASEMENT_FINISH_TYPE.KEY &&
      parseSelectedValueToOption(foundationType).value !== BASEMENT;
    const isHideSwimmingPoolFenchedField: boolean =
      KEY === SWIMMING_POOL_FENCED.KEY && parseSelectedValueToOption(swimmingPool).value === FALSE;
    const isHideSwimmingPoolType: boolean =
      KEY === SWIMMING_POOL_TYPE.KEY && parseSelectedValueToOption(swimmingPool).value === FALSE;
    const isHideReplacementCostField: boolean = KEY === REPLACEMENT_COST.KEY;
    if (KEY === FOUNDATION_TYPE.KEY) {
      dropDownOptionList = getSortedDropDownOptions(dropDownOptionList);
    }
    if (KEY === PREFERRED_DEDUCTIBLE.KEY) {
      dropDownOptionList = getPreferredDeductibleOptionsList();
    }
    if (
      isHideBasementFinishTypeField ||
      isHideSwimmingPoolFenchedField ||
      isHideSwimmingPoolType ||
      isHideReplacementCostField
    ) {
      return;
    }
    if (KEY === BASEMENT_FINISH_PERCENTAGE.KEY) {
      if (parseSelectedValueToOption(foundationType).value !== BASEMENT) {
        return;
      }
      if (parseSelectedValueToOption(basementFinishType)?.value?.toLowerCase() === UNFINISHED) {
        return;
      }
      requireFields.push(BASEMENT_FINISH_PERCENTAGE.KEY);
    }
    return (
      <div key={KEY} className='col-lg-4'>
        <TextField
          className='prop-info-text-filled'
          label={
            <>
              {LABEL}
              {requireFields.includes(KEY) && <span className='error-color'>{ASTERISK}</span>}
            </>
          }
          variant='standard'
          onChange={onChangeHandler}
          onBlur={onBlurPropertyDataFormField}
          InputLabelProps={{  
            shrink: true,
          }}
          inputProps={{
            name: KEY,
            id: KEY,
            autoFocus: false,
          }}
          value={formValues[KEY]}
          error={!!formErrors[KEY]}
          helperText={formErrors[KEY]}
          select={!!OPTIONS?.length}
          SelectProps={{
            onFocus: preventScrollOnFocus,
            MenuProps: {
              onFocus: preventScrollOnFocus,
              autoFocus: false,
              disableAutoFocusItem: true,
              disableEnforceFocus: true,
              disableAutoFocus: true,
            },
          }}
          disabled={!!disabledFormField}
          {...props}
        >
          {createMenuItems(dropDownOptionList)}
        </TextField>
      </div>
    );
  };

  const drawCoverageField = (fieldDetails: InputFieldDetails, props: Record<string, any> = {}) => {
    const { LABEL, KEY } = fieldDetails;
    const isDisabledFormField: boolean =
      !!disabledFormField || KEY === RECONSTRUCTION_COST_ESTIMATOR.KEY || false;
    return (
      <div key={KEY} className='col-lg-4 mb-30'>
        <FormControl fullWidth error={!!formErrors[KEY]}>
          <InputLabel shrink htmlFor={LABEL?.toLowerCase()}>
          <>
            {LABEL}
            {requireFields.includes(KEY) && <span className='error-color'>{ASTERISK}</span>}
          </>
          </InputLabel>
          <Input
            fullWidth
            inputProps={{
              id: KEY,
              name: KEY,
              maxLength: 12,
              decimalScale: 2,
              autoComplete: NONE?.toLowerCase(),
            }}
            disabled={isDisabledFormField}
            value={formValues[KEY]}
            inputComponent={NumberFormatCustom as any}
            onChange={onChangeHandler}
          />
        </FormControl>
        <FormHelperText className='text-red'>
          {formErrors[KEY]}
        </FormHelperText>
      </div>
    );
  }

  const propertyInfoSection = (details: any) => {
    return (
      <>
        <div className='sub-heading mb-30'>
          <Typography className='sub-heading-text'>{details.SUB_TITLE}</Typography>
        </div>
        <div className='row'>
          {Object.values(details.INPUT_FIELDS).map((details: any) => {
            if (coverageFields.includes(details.KEY)) {
              return drawCoverageField(details);
            }
            return [
              PURCHASE_DATE.KEY,
              YEAR_BUILT.KEY,
              ROOF_CONSTRUCTED_OR_REPLACED.KEY,
              UPDATED_ELECTRIC.KEY,
              UPDATED_HEATING.KEY,
              UPDATED_PLUMBING.KEY,
            ].includes(details.KEY)
              ? drawDatePicker(details)
              : drawTextField(details);
          })}
        </div>
      </>
    );
  };

  /** Common function to create DatePicker*/
  const drawDatePicker = (fieldDetails: InputFieldDetails, props: Record<string, any> = {}) => {
    const { LABEL, KEY, MIN_VALUE, MAX_VALUE, DATE_VIEW_TYPE } = fieldDetails;
    return (
      <div key={KEY} className='col-lg-4 mb-30'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormLabel htmlFor={KEY} className={`form-label ${!!formErrors[KEY] && 'error-color'}`}>
            {LABEL}
            {requireFields.includes(KEY) && <span className='error-color'>{ASTERISK}</span>}
          </FormLabel>
          {DATE_VIEW_TYPE === INPUT_FIELD_TYPES.DATE ? (
            <KeyboardDatePicker
              value={formValues[KEY] || null}
              format='MM/dd/yyyy'
              onChange={(_date, value) => datePickerOnChangeHandler(value, KEY)}
              error={!!formErrors[KEY]}
              helperText={formErrors[KEY]}
              minDate={MIN_VALUE}
              maxDate={MAX_VALUE}
              inputValue={formValues[KEY]}
              disabled={!!disabledFormField}
              id={KEY}
              autoOk
              fullWidth
              {...props}
            />
          ) : (
            <KeyboardDatePicker
              views={['year']}
              format='yyyy'
              value={formValues[KEY] || null}
              onChange={(_date, value) => datePickerOnChangeHandler(value, KEY)}
              error={!!formErrors[KEY]}
              helperText={formErrors[KEY]}
              minDate={MIN_VALUE}
              maxDate={MAX_VALUE}
              inputValue={formValues[KEY]}
              disabled={!!disabledFormField}
              id={KEY}
              autoOk
              fullWidth
              {...props}
            />
          )}
        </MuiPickersUtilsProvider>
      </div>
    );
  };

  const propertyInfoSections = [PROPERTY_INFO_DETAILS, COVERAGE_DETAILS, OTHER_DETAILS];

  return (
    <div className='applicant-home-form property-info-form'>
      <div className='container'>
        <div className='row form-wrapper d-flex justify-content-center'>
          <form className='col-lg-11'>
            <PrefillInfoStatusBanner isShowRetryButton={isShowRetryButton} />
            <Typography className='main-heading'>{PROPERTY_INFO.HEADING}</Typography>
            {propertyInfoSections.map(item => propertyInfoSection(item))}
            <div className='row mt-4'>
              <div className='col-md-12'>
                <div className='btnwrap'>
                  <Button variant='outlined' className='btnBackLink' onClick={onBackClick}>
                    <span className='link-label'>
                      <ArrowBackIosIcon className='mr-2' />
                      {BACK}
                    </span>
                  </Button>
                  <>
                    <Button
                      variant='contained'
                      className='btnContinue'
                      disabled={!!propertyInfoLoader || savePropertyInfoloader}
                      onClick={onFormSubmit}
                    >
                      {getPropertyInfoSubmitButtonText()}
                    </Button>
                  </>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ applicant }: TemplateStorePropes) => ({
  applicant,
});

export default connect(mapStateToProps)(Template);

import { isBoolean, startCase } from 'lodash';
import config from '../../config/config';
import {
  HO6,
  INPUT_DATA_TYPES,
  INSURANCE_BUSINESS_TYPES,
  NO,
  QUESTIONNAIRE_PATH,
  QUOTE_APPLICATION_ROUTE,
  QUOTE_ROUTE,
  TRUE,
  YES,
} from '../../constants';
import ThemesImages from '../../pages/components/themes-images';
import store from '../../pages/redux/store';
import { getThemeType } from '../../utils';
import { ERRORS } from '../constants';

const { AUTO, CYBERSECURITY, FLOOD, HOME, HOMEWARRANTY, LIFE, PET, QUAKE, UMBRELLA } =
  INSURANCE_BUSINESS_TYPES;

export const isEnabledPRSv2Flow = () => !!config?.featureEnablePRSv2;

export const extractURLFromGraphQlError = (detail: string = '') => {
  return startCase(detail?.replace(/^\w+\s(http(s)?.+):\s\d+\s/, '') || '');
};

export const extractGraphQlErrorMessage = (error: any): string => {
  const message = error?.error?.message || error?.message;
  return typeof message === INPUT_DATA_TYPES.STRING
    ? extractURLFromGraphQlError(message)
    : ERRORS.SOMETHING_WENT_WRONG_PLS_TRY_AGAIN;
};

export const getPathName = () => {
  if (isEnabledPRSv2Flow()) {
    return QUOTE_APPLICATION_ROUTE;
  }
  if (document.location.href.includes(QUOTE_ROUTE)) {
    return QUOTE_ROUTE;
  }
  return QUESTIONNAIRE_PATH;
};

export const isProductTypeHO6 = () => {
  const {
    home: { productType },
  } = store.getState();
  return !!productType && productType.toLowerCase() === HO6.toLowerCase();
};

export const getLoaderImage = (type: string) => {
  const themeType: string = getThemeType();
  switch (type) {
    case HOME:
      return ThemesImages[themeType].homeLoader;
    case FLOOD:
      return ThemesImages[themeType].floodLoader;
    case AUTO:
      return ThemesImages[themeType].autoLoader;
    case QUAKE:
      return ThemesImages[themeType].quakeLoader;
    case PET:
      return ThemesImages[themeType].petLoader;
    case HOMEWARRANTY:
      return ThemesImages[themeType].homeWarrantyLoader;
    case CYBERSECURITY:
      return ThemesImages[themeType].cyberSecurityLoader;
    case LIFE:
      return ThemesImages[themeType].lifeLoader;
    case UMBRELLA:
      return ThemesImages[themeType].umbrellaLoader;
    default:
      return ThemesImages[themeType].homeLoader;
  }
};

export const getPrefillDataByKeyName = (key: string) => {
  const {
    applicant: { prefillDetails },
  } = store.getState();
  const { propertyData = {} } = prefillDetails || {};
  if (propertyData) {
    return propertyData[key] || '';
  }
  return '';
};

export const getBoolean = (value: string | undefined): boolean | undefined => {
  if (!value?.trim()) {
    return undefined;
  }
  return value === YES;
};

export const getNumber = (value: string | undefined): number | undefined => {
  if (!value?.trim() || isNaN(Number(value))) {
    return undefined;
  }
  return Number(value);
};

export const getBooleanToYesNo = (value: boolean | null | undefined | string = false): string => {
  if (!value) {
    return NO;
  }
  const stringValue = isBoolean(value) ? value.toString() : value.toString();
  return stringValue === TRUE ? YES : NO;
};

export const getPConfigDetails = () => {
  const {
    home: { pconfig },
  } = store.getState();
  return pconfig?.dale_config;
};

import React from 'react';
import { isEmpty, isUndefined, sortBy } from 'lodash';
import {
  CheckCircleOutline as CheckCircleIcon,
  InfoOutlined as InfoIcon,
  WarningOutlined,
} from '@material-ui/icons';
import config from '../config/config';
import {
  InputFieldDetails,
  Options,
  PrefillBannerDetails,
  PropertyData,
} from '../pages/components/property-info/type';
import { FormDataType } from '../pages/custom-hooks';
import store from '../pages/redux/store';
import { getIsLobIncludesInAppointments, setAllValuesToString } from './common';
import { PrefillStatus } from '../pages/components/property-info/property-info-status-banner';
import { getQuoteListOfLob } from '.';
import {
  AL_STATE,
  DEFAULT_DEDUCTABLE,
  DEFAULT_RESIDENCY_STATUS,
  IL_STATE,
  PREFERRED_DEDUCTIBLE,
  PROPERTY_INFO,
} from '../constants/property-info';
import {
  CONTINUE,
  GET_QUOTE,
  HO6,
  INPUT_DATA_TYPES,
  INPUT_FIELD_TYPES,
  LOB_PRODUCTS_MAPPING,
  NUMBER_100,
  NUMBER_ZERO,
  PROPERTY_PREFILL_MESSAGE,
  TRUE,
} from '../constants';
import { ERROR_MESSAGE } from '../pages/custom-hooks/useFormHandler/utils';
import { BasementFinishType } from '../types';
const { COVERAGE_DETAILS, OTHER_DETAILS, PROPERTY_INFO_DETAILS } = PROPERTY_INFO;

export const getIsPropertyInfoEnabledDetails = () => {
  const { applicant } = store.getState();
  const { isPropertyInfoRequired, prefillDetails, prefillStatus } = applicant;
  if (!config?.enablePrefillOverride) {
    return false;
  }
  if (!isUndefined(isPropertyInfoRequired)) {
    return isPropertyInfoRequired;
  }
  return !isEmpty(prefillDetails?.propertyData) || !!prefillStatus;
};

export const getUserPropertyInfoPayload = (
  updatedUserData: FormDataType,
  keysForAdjustmentsObj: Array<string>
) => {
  const { applicant } = store.getState();
  const { prefillDetails } = applicant;
  if (!prefillDetails?.propertyData?.rebuildingCost) {
    const propertyData = prefillDetails?.propertyData || {};
    updatedUserData = {
      ...propertyData,
      ...updatedUserData,
    };
  }

  let tempPayload: Record<string, any> = {};
  Object.keys(updatedUserData).forEach(key => {
    const value = updatedUserData[key];
    if (keysForAdjustmentsObj.includes(key)) {
      if (!isEmpty(tempPayload?.adjustments)) {
        tempPayload = {
          ...tempPayload,
          adjustments: {
            ...tempPayload.adjustments,
            [key]: {
              value,
            },
          },
        };
      } else {
        tempPayload = {
          ...tempPayload,
          adjustments: {
            [key]: {
              value,
            },
          },
        };
      }
    } else {
      tempPayload[key] = value;
    }
  });
  return { payload: tempPayload };
};

export const removePropertyInfoLabelFromKeys = (submittedDate: FormDataType) => {
  let tempSubmittedDate: Record<string, any> = {};
  Object.keys(submittedDate).forEach(key => {
    const [value] = submittedDate[key].split('~');
    tempSubmittedDate[key] = value;
  });
  return tempSubmittedDate;
};

export const mappedResponseToStoreForPropertyDetails = (prefillData: any) => {
  let tempPrefillData: Record<string, any> = {};
  if (!!prefillData && !isEmpty(prefillData)) {
    const preferredDeductible = getPreferredDeductible();
    const residencyStatus = getResidencyStatus();
    const { propertyData, adjustments, label } = prefillData;
    if (!isEmpty(propertyData)) tempPrefillData.propertyData = setAllValuesToString(propertyData);
    const tempAdjustments: Record<string, any> = {};
    if (!isEmpty(adjustments)) {
      Object.keys(adjustments).forEach(key => {
        tempAdjustments[key] = adjustments[key]?.value;
      });
    }
    tempPrefillData.propertyData = {
      ...tempPrefillData.propertyData,
      ...setAllValuesToString(tempAdjustments),
    };
    if (preferredDeductible) {
      tempPrefillData.propertyData[COVERAGE_DETAILS?.INPUT_FIELDS?.PREFERRED_DEDUCTIBLE?.KEY] =
        preferredDeductible;
      if (label) {
        label[COVERAGE_DETAILS?.INPUT_FIELDS?.PREFERRED_DEDUCTIBLE?.KEY] = undefined;
      }
    }
    if (residencyStatus) {
      tempPrefillData.propertyData[PROPERTY_INFO_DETAILS.INPUT_FIELDS.RESIDENCY_STATUS.KEY] =
        residencyStatus;
      if (label) {
        label[PROPERTY_INFO_DETAILS.INPUT_FIELDS.RESIDENCY_STATUS.KEY] = undefined;
      }
    }
    if (!isEmpty(label)) tempPrefillData.label = setAllValuesToString(label);
  }
  return tempPrefillData;
};

export const isProdcuctTypeHO6 = () => {
  const {
    home: { productType },
  } = store.getState();
  return !!productType && productType.toLowerCase() === HO6.toLowerCase();
};

export const parseValueToPropertyData = (
  newValue: string,
  field: InputFieldDetails,
  key: string
): {
  newLabel: PropertyData;
  parsedValue: unknown;
} => {
  if (!newValue) {
    return { newLabel: {}, parsedValue: newValue };
  }
  const newLabel: PropertyData = {};
  let parsedValue;
  const { DATA_TYPE, TYPE } = field;
  const { NUMBER, BOOLEAN } = INPUT_DATA_TYPES;
  if (TYPE === INPUT_FIELD_TYPES.SELECT) {
    let [value, label] = newValue.split('~');
    newValue = value;
    newLabel[key] = label;
  }
  if (DATA_TYPE === NUMBER) parsedValue = Number(newValue);
  else if (DATA_TYPE === BOOLEAN) parsedValue = newValue === TRUE;
  else parsedValue = newValue;
  return { newLabel, parsedValue };
};

export const getSelectedOptionFromDropDownList = (options: Options[], value?: string) => {
  let selectedOption: Options[] = [];
  selectedOption = options.filter((option: Options) => option.value === value);
  if (isEmpty(selectedOption)) {
    selectedOption = options.filter((option: Options) => !!option?.default);
  }
  return !isEmpty(selectedOption) ? selectedOption[0] : null;
};

export const getSortedDropDownOptions = (options: Options[]) => {
  return !isEmpty(options) ? sortBy(options, (item: Options) => item?.label) : [];
};

export const parseSelectedValueToOption = (selectedValue: string) => {
  if (!selectedValue) return { label: '', value: '' };
  const [value, label] = selectedValue.split('~');
  return { value, label };
};

export const getBannerDetailsByPrefillStatus = (status: PrefillStatus): PrefillBannerDetails => {
  const prifillDetailsList: Record<string, PrefillBannerDetails> = {
    [PrefillStatus.FAILED]: {
      message: PROPERTY_PREFILL_MESSAGE.FAILED,
      icon: <InfoIcon />,
      className: 'error',
      isRetryButtonVisible: true,
    },
    [PrefillStatus.INCOMPLETE]: {
      message: PROPERTY_PREFILL_MESSAGE.INCOMPLETE,
      icon: <WarningOutlined />,
      className: 'warning',
      isRetryButtonVisible: false,
    },
    [PrefillStatus.SUCCESS]: {
      message: PROPERTY_PREFILL_MESSAGE.SUCCESS,
      icon: <CheckCircleIcon />,
      className: 'success',
      isRetryButtonVisible: false,
    },
  };
  return prifillDetailsList[status];
};

export const isIncludesPropertyCoverage = (formValues: FormDataType) => {
  return (
    !!parseInt(formValues?.coverageA) &&
    !!parseInt(formValues?.coverageC) &&
    !!parseInt(formValues?.coverageD) &&
    !!parseInt(formValues?.coverageE) &&
    !!parseInt(formValues?.coverageF)
  );
};

export const getIsSubmitPropertyInfoForLob = (isPropertyInfoChanges: boolean, lob: string) => {
  return (
    getIsLobIncludesInAppointments(lob) &&
    (isPropertyInfoChanges || (!isPropertyInfoChanges && isEmpty(getQuoteListOfLob(lob))))
  );
};

export const getPropertyInfoSubmitButtonText = () => {
  const { floodDetails, common } = store.getState();
  const isHomeLob: boolean = getIsLobIncludesInAppointments(LOB_PRODUCTS_MAPPING.home);
  const isFloodLob: boolean = getIsLobIncludesInAppointments(LOB_PRODUCTS_MAPPING.flood);
  const isFloodLobSelected: boolean =
    common?.selectedBusinessOption?.toLowerCase() === LOB_PRODUCTS_MAPPING.flood;
  if (
    (isHomeLob && !isFloodLob) ||
    (isFloodLob && !floodDetails?.annexPrefill?.isFloodInfo && (!isHomeLob || isFloodLobSelected))
  ) {
    return GET_QUOTE;
  }
  if (
    isFloodLob &&
    !!floodDetails?.annexPrefill?.isFloodInfo &&
    (!isHomeLob || isFloodLobSelected)
  ) {
    return CONTINUE;
  }
  return GET_QUOTE;
};

export const getDefaultOptionFromOptions = (options: Options[]) => {
  const option = options.find(option => option.default);
  return option?.value + '~' + option?.label;
};

export const getPropertyInfoFields = () => {
  return [
    ...Object.values(PROPERTY_INFO_DETAILS.INPUT_FIELDS),
    ...Object.values(OTHER_DETAILS.INPUT_FIELDS),
    ...Object.values(COVERAGE_DETAILS.INPUT_FIELDS),
  ];
};
// Regular expression to match the date format (MM/DD/YYYY).
export const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;

export const validateDate = (
  selectedDate: string,
  minDate: string = '',
  maxDate: string = '',
  isRequired: boolean = false
) => {
  if (isEmpty(selectedDate) && !!isRequired) return ERROR_MESSAGE.REQUIRED;
  if (
    !isEmpty(selectedDate) &&
    (!datePattern.test(selectedDate) ||
      new Date(selectedDate) < new Date(minDate) ||
      new Date(selectedDate) > new Date(maxDate))
  ) {
    return ERROR_MESSAGE.INVALID;
  }
  return '';
};

export const validateBasementFinishPercentage = (basementFinishPercentageValue: string) => {
  const percentageValue = Number(basementFinishPercentageValue);
  if (isEmpty(basementFinishPercentageValue)) return ERROR_MESSAGE.REQUIRED;
  if (percentageValue <= NUMBER_ZERO || percentageValue > NUMBER_100)
    return ERROR_MESSAGE.PERCENTAGE;
  return '';
};

export const getBasementFinishTypeByBasementFinishPercentage = (
  basementFinishPercentage: number
) => {
  if (basementFinishPercentage === NUMBER_ZERO) {
    return BasementFinishType.UNFINISHED;
  }
  if (basementFinishPercentage === NUMBER_100) {
    return BasementFinishType.FINISHED;
  }
  return BasementFinishType.PARTIALLY_FINISHED;
};

export const getPreferredDeductibleOptionsList = () => {
  let optionList: Options[] = COVERAGE_DETAILS.INPUT_FIELDS.PREFERRED_DEDUCTIBLE.OPTIONS;
  const { applicant } = store.getState();
  const state = applicant?.address?.state?.toUpperCase() || '';
  if (state === AL_STATE) {
    return PREFERRED_DEDUCTIBLE.AL;
  }
  if (state === IL_STATE) {
    return PREFERRED_DEDUCTIBLE.IL;
  }
  return optionList;
};

export const getPreferredDeductible = () => {
  const { applicant } = store.getState();
  return !!applicant?.personal_details?.preferredDeductible
    ? applicant?.personal_details?.preferredDeductible?.toString()
    : DEFAULT_DEDUCTABLE.value;
};

export const getResidencyStatus = () => {
  const { applicant } = store.getState();
  return !!applicant?.personal_details?.residenceType
    ? applicant?.personal_details?.residenceType
    : DEFAULT_RESIDENCY_STATUS;
};

export const isPropertyInfoCovered = () => {
  const { prefillDetails } = store.getState().applicant || {};
  const { propertyData } = prefillDetails || {};
  if (isEmpty(propertyData)) {
    return false;
  }
  return (
    !!parseInt(propertyData?.coverageA) &&
    !!parseInt(propertyData?.coverageC) &&
    !isEmpty(propertyData?.yearBuilt)
  );
};

import DateFnsUtils from '@date-io/date-fns';
import { FormControl, InputLabel } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { FormFieldProps } from '../form-fields.types';
import { DateViewType } from '../form-fields.enums';
import { ASTERISK } from '../../../../constants';

const DateField: React.FC<FormFieldProps> = ({
  fieldDetails: { name, dateViewType, label, isRequired, isDisabled, minDate, maxDate },
  value,
  error,
  disabled,
  onChange,
  onInputBlur,
}) => {
  const handleChange = (date: Date | null, value?: string | null) => {
    onChange({
      target: {
        name,
        value: value || null,
      },
    } as React.ChangeEvent<any>);
  };

  const commonProps = {
    value: value || null,
    onChange: handleChange,
    onBlur: () => onInputBlur && onInputBlur(name, value),
    error: !!error,
    helperText: error,
    disabled: disabled || !!isDisabled,
    id: name,
    autoOk: true,
    fullWidth: true,
    minDate,
    maxDate,
    autoComplete: 'off',
  };

  return (
    <div>
      <InputLabel shrink error={!!error} htmlFor={name}>
        {label}
        {isRequired && <span className='error-color'>{ASTERISK}</span>}
      </InputLabel>
      <FormControl fullWidth key={name} error={!!error}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {dateViewType === DateViewType.YEAR ? (
            <KeyboardDatePicker
              {...commonProps}
              views={['year']}
              format='yyyy'
            />
          ) : (
            <KeyboardDatePicker
              {...commonProps}
              format='MM/dd/yyyy'
            />
          )}
        </MuiPickersUtilsProvider>
      </FormControl>
    </div>
  );
};

export default DateField;

/* istanbul ignore file */
// @ts-nocheck
import {
  assignIn,
  capitalize,
  difference,
  every,
  filter,
  find,
  flatMap,
  forEach,
  get,
  has,
  includes,
  isEmpty,
  isEqual,
  isNaN,
  isNumber,
  isObject,
  isUndefined,
  keys,
  map,
  omit,
  orderBy,
  sortBy,
  startCase,
  transform,
} from 'lodash';
import moment from 'moment';
import { v4 } from 'uuid';
import React, { Fragment, useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import Cookies from 'universal-cookie';
import {
  ADDRESS,
  ASSISTED_BY,
  CARRIER,
  CARRIER_FAILED_TO_RESPOND_ERROR_MESSAGE,
  CUSTOM_ID,
  CYBER_SECURITY,
  DEFAULT_LOB_PATH,
  DEFAULT_PAGE_TRACKING,
  EARTH_QUAKE_LOB,
  EMAIL,
  EZLYNX,
  FOUNDATION_TYPES,
  HEAP_IDENTITY,
  HEAP_LABELS,
  HTML_EM,
  INSURANCE_PRODUCTS,
  INVALID,
  LOAN_NUMBER_LABEL,
  LOB_PRODUCTS_MAPPING,
  MINIMUM_AGE,
  MIMIMUM_AGE_ERROR_MESSAGE,
  NO_COVERAGE,
  OTHER_PRODUCTS,
  PHONE_NUMBER,
  PHONE_NUMBER_KEY,
  POLICY_TERMS,
  PREFILL_ERROR_CARRIER_ID,
  PREMIUM,
  PREMIUM_VALIDATION_DETAILS,
  QUOTING_STEPS_PAGES_NAME,
  REQUIRED,
  SALESFORCE_CUSTOMER_ID,
  SLAB,
  SUBMIT_VALUES,
  STEPPER_KEYS_MAPPING,
  UNLIMITED,
} from '../constants';
import { OCCUPATION_DATA } from '../constants/occupation';
import history from '../history';
import store from '../pages/redux/store';
import QuestionList from '../constants/flood-questionnaire';
import {
  ANNUALLY,
  CYBER_DEFAULT_PLAN,
  FAILED,
  INSURANCE_BUSINESS_TYPES,
  MONTHLY,
  PRODUCT_MAPPING,
  SILVER,
  SEMI_ANNUALLY,
} from '../constants/index';
import {
  clearAutoDataOnApplicantChanges,
  clearDriverInfo,
  clearInsurenaceDetails,
  clearVehiclesList,
  fetchPconfig,
  fetchSFDCLOBDetails,
  getBridgeToCarrierDetailsForCyber,
  getBridgeToCarrierDetailsForLife,
  getBridgeToCarrierDetailsForPet,
  getBridgeToCarrierDetailsForFlood,
  setAutoQuoteDetails,
  setFloodHerdId,
  setFloodQuotes,
  setHeapDetailsLoaded,
  setHomeState,
  setIsVeriskFetched,
  setLobPriority,
  setPetQuotes,
  setPetBreedsDetails,
  setPageTracking,
  setPrefillData,
  setQuakeHerdId,
  setQuoteCompleted,
  setQuakeQuotes,
  setQuotesLoading,
  setUmbrellaHerdId,
  stopPolling,
  storeCyberSecurityQuoteDetails,
  storeHomeWarrantyQuoteList,
  storeLifeQuoteDetails,
  storePetListData,
  storeUmbrellaQuoteDetails,
  trackCarrierBridging,
  updateApplicantAddress,
  updateApplicantDetails,
  updateDriverName,
  updateRequoteProducts,
  updateSelectedCarriers,
  setFloodDetails,
  storeQuakeDetails,
  storePetDetails,
} from '../pages/redux/actions';
import { UMBRELLA_LOB_CODE } from '../constants/umbrella-questionnaire';
import config from '../config/config';
import {
  getIsLobIncludesInAppointments,
  isEmptyValues,
  shouldApplicantHasPreviousAddress,
} from './common';
import { LineOfBusiness } from '../quote-application/enums';
import { isEnabledPRSv2Flow } from '../quote-application/utils';
export * from './common';
export * from './property-info';

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

// Convert the number to currency with commas
export const formatNumberToCurrency = (number: any) => {
  if (number === NO_COVERAGE || number === UNLIMITED) {
    return number;
  }
  if (number) {
    let currency = String(number);
    number = Number(currency?.replace(',', ''));
  }
  if (isNumeric(number) || isNumber(number)) {
    return Number(number)
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      .replace(/^/, '$');
  } else {
    return '-NA-';
  }
};
// Phone input regex
export const phoneRegex =
  /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;

// Format masked phone number to plain number
export const simplifyPhoneNumber = (phNumber: any): string => {
  let phoneNumber = phNumber;
  phoneNumber = !isEmpty(phoneNumber) ? startCase(phoneNumber).replace(/\D/g, '') : '';
  return phoneNumber.trim();
};

// Check valid phone number for ezlynx
export const validatePhoneNumber = (phNumber: any): boolean => {
  let phoneNumber = simplifyPhoneNumber(phNumber);
  phoneNumber = phoneNumber.charAt(3);
  if (phoneNumber === '0') {
    return false;
  }
  return true;
};

// Address street and city in upper case
export const getStartCase = (value: string) => {
  return value.split(' ').map(capitalize).join(' ').trim();
};

export const numberFormatter = (num: any) =>
  Math.abs(num) > 999
    ? `${Math.sign(num) * ((Math.abs(num) / 1000) as any).toFixed(0)}K`
    : Math.sign(num) * Math.abs(num);

export const numberFormatterWithDecimal = (num: any) =>
  Math.abs(num) > 999
    ? `${Math.sign(num) * ((Math.abs(num) / 1000) as any).toFixed(1)}K`
    : Math.sign(num) * Math.abs(num);

export const formatCurreny = (value: string | number) => String(value).replace(/,/g, '');

export const numberToCurrency = (value: string | number) => {
  if (!value) return 0;
  value = formatCurreny(value);
  if (Math.abs(value) >= 999 && Math.abs(value) < 1000000) {
    return (Math.abs(value) / 1000).toFixed(0) + 'K';
  } else if (Math.abs(value) >= 1000000) {
    return (Math.abs(value) / 1000000).toFixed(0) + 'M';
  } else if (Math.abs(value) <= 900) {
    return Math.abs(value);
  }
};

// eslint-disable-next-line
export const emailRegex = /^(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6}$/;

// String regex for first name, middle name and last name and also for loan officer name
export const stringRegex = /^[A-Za-z]{1}[-'\u2019 A-Za-z]*$/;

// String with space regex
export const stringWithSpaceRegex = /^[a-zA-Z ]*$/;

// Only number regex
export const numberRegex = /^[0-9]*$/;

// Default DOB format
export const dateFormat = 'MM/DD/YYYY';

// Date difference
export const getDateDifference = (dateOfBirth: any) => {
  let differenceYear;
  if (moment(dateOfBirth).isValid()) {
    differenceYear = parseFloat(moment(dateOfBirth, dateFormat).fromNow().split(' ')[0]);
    if (isNaN(differenceYear)) {
      differenceYear = 0;
    }
  }
  return differenceYear;
};

export const replaceWithPlaceholder = (content: string, text: string) =>
  content.replace(/__PLACEHOLDER__/g, text);

export const downloadFile = (fileUrl: string) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getBoundedQuote = (quoteList: Array<any>) =>
  filter(quoteList, quote => quote.policyBounded);

export const getQuoteListWithBindDetails = (quoteList: Array<any>, carrierId: string) =>
  map(quoteList, (quote: any) => {
    if (
      quote?.carrier_id === String(carrierId) ||
      quote?.carrier_id === parseInt(carrierId) ||
      quote?.carrier_id === carrierId
    ) {
      return { ...quote, policyBounded: true };
    }
    return quote;
  });

  export const segregateListOptions = (list: Array<any>, options: Array<any>) => {
    const listMap = new Map<string, any>();
    list.forEach(item => {
      const carrierId = item?.carrier_id?.toString() || item?.carrierId?.toString();
      if (carrierId && !item?.error) {
        listMap.set(carrierId, item);
      }
    });
    const existingOptions: Array<any> = [];
    const remainingOptions: Array<any> = [];
    options.forEach(option => {
      const optionCarrierId = option?.carrierId?.toString();
      if (optionCarrierId && listMap.has(optionCarrierId)) {
        existingOptions.push(option);
      } else {
        remainingOptions.push(option);
      }
    });
    return {
      existingOptions: orderBy(existingOptions, ['name'], 'asc'),
      remainingOptions: orderBy(remainingOptions, ['name'], 'asc'),
    };
  };

export const getCarrierName = (details: any) => {
  const name =
    details?.name ?? details?.carrier_name ?? details?.Carrier ?? details?.carrierName ?? '';
  return String(name).trim();
};

export const getCarrierPremium = (details: any) => {
  let premiumValue = '';
  if (details) {
    if (details.floodQuote) {
      const sortedPremiums = sortBy(details.premiumDetails, ['premium']);
      premiumValue = sortedPremiums[0].premium;
    } else {
      premiumValue = details.premium || details.price;
    }
  }
  return premiumValue;
};

export const getCarrierPremiumTerm = (details: any, defaultTerm: any = POLICY_TERMS[0].value) => {
  if (!!details?.quote?.data?.term) {
    return details?.quote?.data?.term;
  }
  return details && details.term ? details.term : defaultTerm;
};

export const getValue = (value: any, list: Array<any>, callBack?: any, key?: string) => {
  if (['string', 'number'].includes(typeof value)) {
    return !isEmpty(String(value))
      ? value
      : list.length === 1
      ? callBack
        ? callBack(list[0])
        : list[0][key]
      : '';
  } else {
    return '';
  }
};

export const removeExtraSpaces = (value: string) => value.replace(/\s+/g, ' ').trim();

export const validateDOB = (dateOfBirth: any) => {
  const differenceYear: number = getDateDifference(dateOfBirth) || 0;
  if (!dateOfBirth || dateOfBirth.length === 0) {
    return REQUIRED;
  }
  if ((dateOfBirth.length > 0 && !moment(dateOfBirth).isValid()) || differenceYear > 99) {
    return INVALID;
  } else if (
    dateOfBirth.length > 0 &&
    moment(dateOfBirth).isValid() &&
    moment().diff(dateOfBirth, 'years') < MINIMUM_AGE
  ) {
    return MIMIMUM_AGE_ERROR_MESSAGE;
  }
  return '';
};

// String AlphaNumeric regex.
export const stringAlphaNumericRegex = /^[0-9a-zA-Z]+$/;

export const validateAlphaNumeric = (data: string) => {
  if (!data.match(stringAlphaNumericRegex)) {
    return INVALID;
  }
  return '';
};

// Used to convert phone number to "111-111-1111" format
export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return null;
};

// Used to convert phone number to "(111) 111-1111" format
export const formatPhoneNumberWithBrackets = (phoneNumberString: string) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return '';
};

// Format number to U.S. phone number. (Allows the optional +1 international code)
export const formatUSPhoneNumber = (phoneNumberString: string) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const getOccupationList = (
  industryData: Array<{ label: string; value: string; id: number }>,
  value: string
): {
  label: string;
  value: string;
}[] => {
  const currentValue = find(industryData, { value });
  if (!isEmpty(value) && !isEmpty(currentValue)) {
    return OCCUPATION_DATA[currentValue.id];
  } else {
    return [];
  }
};

export const getDefaultOccupation = (currentValue: string, list: Array<any>) => {
  const currentDataVal = filter(list, o => {
    return o.value === currentValue;
  });

  if (isEmpty(currentValue) || isEmpty(currentDataVal)) {
    return '';
  } else {
    return currentValue;
  }
};

export const descendingComparator = (a: any, b: any, orderByDetails: string) => {
  if (isEmpty(a) || isEmpty(b)) return 0;

  if (!includes(orderByDetails, [PHONE_NUMBER, EMAIL])) {
    return generalComparator(a[orderByDetails], b[orderByDetails]);
  }

  if (orderByDetails === PHONE_NUMBER) {
    const x = replaceNonNumerics(a[orderByDetails]);
    const y = replaceNonNumerics(b[orderByDetails]);
    return generalComparator(x, y);
  }

  if (orderByDetails === EMAIL) {
    const x = !isEmpty(a[orderByDetails]) ? a[orderByDetails]?.toLowerCase() : '';
    const y = !isEmpty(b[orderByDetails]) ? b[orderByDetails]?.toLowerCase() : '';
    return generalComparator(x, y);
  }

  return 0;
};

export const generalComparator = (a: string, b: string) => {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
};

export const replaceNonNumerics = (value: string) => Number(value.replace(/[^0-9]/g, ''));

export const getComparator = (orderDetails: string, orderByDetails: string) => {
  return orderDetails === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderByDetails)
    : (a: any, b: any) => -descendingComparator(a, b, orderByDetails);
};

export const stableSort = (rows: Array<any>, comparator: any) => {
  const stabilizedThis = rows.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const orderDetails = comparator(a[0], b[0]);
    if (orderDetails !== 0) {
      return orderDetails;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const getEmptyRowCount = (emptyRows: number, list: Array<any>, rowsPerPage: number) => {
  if (emptyRows === 0) {
    const listCheck = every(list, isUndefined);
    if (listCheck) {
      emptyRows = list.length;
    }
  }
  if (emptyRows > rowsPerPage) {
    emptyRows = rowsPerPage;
  }
  return emptyRows;
};

const flatten = (target: any, children: any) => {
  React.Children.forEach(children, (child: any) => {
    if (React.isValidElement(child)) {
      if (child.type === Fragment) {
        flatten(target, child.props.children);
      } else {
        target.push(child);
      }
    }
  });
};

export const FragmentSwitch = ({ children }) => {
  const flattenedChildren = [];
  flatten(flattenedChildren, children);
  return React.createElement.apply(React, [Switch, null].concat(flattenedChildren));
};

export const checkFloodQuote = (quote: any) => {
  if (quote.floodQuote) {
    return true;
  }
  return false;
};

export const productFromOtherQuotes = (product: string) => {
  const productTrim = product ? product.trim() : product;
  const isOtherProduct = OTHER_PRODUCTS.filter(otherProduct => otherProduct.value === productTrim);
  return !isEmpty(isOtherProduct) ? true : false;
};

export const checkPolicyBounded = (data: any, lob: string, quoteList?: Array<any>) => {
  let quoteBounded = has(data, lob) && data[lob];
  if (lob === INSURANCE_PRODUCTS[0] && quoteBounded) {
    const validOtherQuote = quoteList.filter(quote => quote.floodQuote && !quote.policyBounded);
    if (!isEmpty(validOtherQuote)) {
      OTHER_PRODUCTS.forEach(otherProduct => {
        quoteBounded = has(data, otherProduct.value);
      });
    }
  }
  return quoteBounded;
};

export const getCarrierOptionList = (options: any, lob?: string) => {
  lob = !!lob && lob?.toLowerCase() === INSURANCE_PRODUCTS[3] ? EARTH_QUAKE_LOB : lob;
  if (isEmpty(options)) {
    return [];
  }
  let carriers = { ...options };
  if (lob) {
    carriers = { ...carriers[lob] };
  }
  return flatMap(carriers);
};

const checkValidFloodQuote = (list: Array<any>) =>
  find(list, quote => quote.floodQuote && !quote.policyBounded);

const checkValidHomeQuote = (list: Array<any>) =>
  find(list, quote => !quote.floodQuote && !quote.policyBounded);

export const availableAMSLobs = (boundDetails: any, currentLob: string, quoteList?: Array<any>) => {
  const mainLobs = [INSURANCE_PRODUCTS[0]];
  const otherLobs = OTHER_PRODUCTS.map(product => product.value);
  const allLobs = [...mainLobs, ...otherLobs];
  const boundedLobs = keys(boundDetails);
  let lobs = [];
  if (currentLob === INSURANCE_PRODUCTS[1]) {
    lobs = [INSURANCE_PRODUCTS[1]];
  } else if (currentLob === INSURANCE_PRODUCTS[0]) {
    if (!isEmpty(boundDetails)) {
      lobs = difference(allLobs, boundedLobs);
    } else {
      lobs = [...allLobs];
    }
    if (quoteList) {
      const floodQuotes = checkValidFloodQuote(quoteList);
      if (isEmpty(floodQuotes)) {
        lobs = lobs.filter(lob => lob !== INSURANCE_PRODUCTS[2]);
      }
      const homeQuotes = checkValidHomeQuote(quoteList);
      if (isEmpty(homeQuotes)) {
        lobs = lobs.filter(lob => lob !== INSURANCE_PRODUCTS[0]);
      }
    }
  } else {
    lobs = difference(otherLobs, boundedLobs);
  }
  return lobs;
};

export const excludeAmsForLobs: Array<string> = [
  'pet',
  'homewarranty',
  'cybersecurity',
  'life',
  'umbrella',
];

export const showAmsButtonAsPerLob = () => {
  const {
    common,
    partner: { appointments },
  } = store.getState();
  const { selectedBusinessOption } = common;
  let selectedLobList: array<any> = !isEmpty(appointments) ? Object.keys(appointments) : [];
  let selectedLob: string = !isEmpty(selectedBusinessOption)
    ? selectedBusinessOption?.toUpperCase()
    : '';
  let showAMSForNoQuotes: any = {
    auto: false,
    flood: false,
    home: false,
    quake: false,
  };
  if (
    !isEmpty(selectedLobList) &&
    !isEmpty(selectedLob) &&
    selectedLobList.includes(selectedLob) &&
    !excludeAmsForLobs.includes(selectedLob.toLowerCase())
  ) {
    showAMSForNoQuotes[selectedLob?.toLowerCase()] =
      !common?.boundedPolicyDetails?.[selectedLob?.toLowerCase()] &&
      !common?.policyBounded?.[selectedLob?.toLowerCase()]
        ? true
        : false;
  }
  return showAMSForNoQuotes;
};

export const getLineOfBusiness = () => {
  const {
    common,
    partner: { appointments },
  } = store.getState();
  let selectedLobList: array<any> = !isEmpty(appointments) ? Object.keys(appointments) : [];
  let lobList: Array<any> = [];
  if (!isEmpty(selectedLobList)) {
    if (
      !common?.boundedPolicyDetails?.home &&
      !common?.policyBounded?.home &&
      selectedLobList.includes(INSURANCE_PRODUCTS[0]?.toUpperCase())
    ) {
      lobList.push(INSURANCE_PRODUCTS[0]);
    }
    if (
      !common?.boundedPolicyDetails?.auto &&
      !common?.policyBounded?.auto &&
      selectedLobList.includes(INSURANCE_PRODUCTS[1]?.toUpperCase())
    ) {
      lobList.push(INSURANCE_PRODUCTS[1]);
    }
    if (
      !common?.boundedPolicyDetails?.flood &&
      !common?.policyBounded?.flood &&
      selectedLobList.includes(INSURANCE_PRODUCTS[2]?.toUpperCase())
    ) {
      lobList.push(INSURANCE_PRODUCTS[2]);
    }
    if (
      !common?.boundedPolicyDetails?.quake &&
      !common?.policyBounded?.quake &&
      selectedLobList.includes(INSURANCE_PRODUCTS[3]?.toUpperCase())
    ) {
      lobList.push(INSURANCE_PRODUCTS[3]);
    }
    if (
      !common?.boundedPolicyDetails?.umbrella &&
      !common?.policyBounded?.umbrella &&
      selectedLobList.includes(INSURANCE_PRODUCTS[8]?.toUpperCase()) &&
      !excludeAmsForLobs.includes(INSURANCE_PRODUCTS[8])
    ) {
      lobList.push(INSURANCE_PRODUCTS[8]);
    }
  }
  return lobList;
};

export const checkValueWithOrganization = (list: Array<any>, code: string, key: string) => {
  const orgAMSCode = list?.length === 1 ? list[0].GLDivisionCode : '';
  return {
    code,
    key,
    orgAMSCode,
    orgPresent: !isEmpty(orgAMSCode),
  };
};

const getDepartmentValue = (value: any, list: Array<any>, key: string) => {
  if (isEmpty(value) && !isEmpty(list)) {
    if (list.length > 1) {
      const defaultValue = find(list, details => details[key] === '^^^');
      if (defaultValue) {
        return defaultValue[key];
      }
      return '';
    }
    return list[0][key];
  } else {
    const valueExists = find(list, details => details[key] === value);
    return valueExists ? value : '';
  }
};

const getCarrierDetails = (carrierId: any, quoteList: Array<any>) => {
  return filter(quoteList, quoteDetails => String(quoteDetails.carrierId) === String(carrierId))[0];
};

const getValueByOrg = (value: any, list: Array<any>, code: string, key: string) => {
  if (isEmpty(value) && !isEmpty(list)) {
    const { orgAMSCode, orgPresent } = checkValueWithOrganization(list, code, key);
    if (!orgPresent) {
      return getValue(value, list, null, key);
    } else {
      return orgAMSCode;
    }
  } else {
    return value;
  }
};

export const overrideSfdcAMSFormDetails = (details: any, selectedOrgCode: string) => {
  const { carrierList } = details;
  const list = getCarrierOptionList(carrierList);
  const carrierDetails = getCarrierDetails(get(details, 'carrierId', ''), list);
  const carrierName = carrierDetails
    ? getCarrierName(carrierDetails)
    : !!details.carrierName
    ? details.carrierName
    : '';
  return {
    effectiveDate: !isEmpty(details.effectiveDate)
      ? details.effectiveDate
      : moment().format(dateFormat),
    addressDetails: !isEmpty(details.addressDetails) ? details.addressDetails : {},
    applicant: !isEmpty(details.applicant) ? details.applicant : {},
    selectedCarriers: !isEmpty(details.selectedCarriers) ? details.selectedCarriers : {},
    carrierName: !isEmpty(carrierName) ? carrierName : '',
    lineOfBusiness: !isEmpty(details.selectedLOB)
      ? details.selectedLOB
      : !isEmpty(details.lineOfBusiness)
      ? details.lineOfBusiness
      : '',
    premium: get(details, 'premium', ''),
    term: get(details, 'term', ''),
    carrierUnderwriter: get(details, 'carrierUnderwriter', ''),
    policyNumber: get(details, 'policyNumber', ''),
    division: getValueByOrg(
      details.division,
      get(details, 'divisionList', []),
      selectedOrgCode,
      'GLDivisionCode'
    ),
    executive: getValue(
      details.executive,
      get(details, 'executivesList', []),
      null,
      'EmployeeCode'
    ),
    representative: getValue(
      details.representative,
      get(details, 'representativesList', []),
      null,
      'EmployeeCode'
    ),
    group: getValue(details.group, get(details, 'groupList', []), null, 'GLGroupCode'),
    department: getDepartmentValue(
      details.department,
      get(details, 'departmentList', []),
      'GLDepartmentCode'
    ),
    employee: getValue(details?.employee, get(details, 'employee', []), null, 'EmployeeCode'),
    broker: getValue(details.broker, get(details, 'brokerList', []), null, 'BrokerCode'),
    carrierUnderwriterOptions: [],
    carrierOptions: get(details, 'carrierList', []),
    divisionOptions: get(details, 'divisionList', []),
    executiveOptions: get(details, 'executivesList', []),
    representativeOptions: get(details, 'representativesList', []),
    groupOptions: get(details, 'groupList', []),
    departmentOptions: get(details, 'departmentList', []),
    employeeOptions: get(details, 'employeeList', []),
    brokerOptions: get(details, 'brokerList', []),
    lobOption: get(details, 'lobList', []),
    code: get(details, 'code', ''),
    eoiUploadRequired: get(details, 'eoiUploadRequired', false),
  };
};

export const overrideAMSDetails = (details: any, selectedOrgCode: string) => {
  const { carrierList } = details;
  const list = getCarrierOptionList(carrierList);
  const carrierDetails = getCarrierDetails(get(details, 'carrierId', ''), list);
  const carrierName = carrierDetails ? getCarrierName(carrierDetails) : '';
  return {
    effectiveDate: !isEmpty(details.effectiveDate)
      ? details.effectiveDate
      : moment().format(dateFormat),
    carrierName: !isEmpty(carrierName) ? carrierName : '',
    premium: get(details, 'premium', ''),
    term: get(details, 'term', ''),
    carrierUnderwriter: get(details, 'carrierUnderwriter', ''),
    policyNumber: get(details, 'policyNumber', ''),
    division: getValueByOrg(
      details.division,
      get(details, 'divisionList', []),
      selectedOrgCode,
      'GLDivisionCode'
    ),
    executive: getValue(
      details.executive,
      get(details, 'executivesList', []),
      null,
      'EmployeeCode'
    ),
    representative: getValue(
      details.representative,
      get(details, 'representativesList', []),
      null,
      'EmployeeCode'
    ),
    group: getValue(details.group, get(details, 'groupList', []), null, 'GLGroupCode'),
    assistedBy: details?.assistedBy || '',
    department: getDepartmentValue(
      details.department,
      get(details, 'departmentList', []),
      'GLDepartmentCode'
    ),
    employee: getValue(details?.employee, get(details, 'employeeList', []), null, 'EmployeeCode'),
    broker: getValue(details.broker, get(details, 'brokerList', []), null, 'BrokerCode'),
    carrierUnderwriterOptions: [],
    carrierOptions: get(details, 'carrierList', []),
    divisionOptions: get(details, 'divisionList', []),
    executiveOptions: get(details, 'executivesList', []),
    representativeOptions: get(details, 'representativesList', []),
    groupOptions: get(details, 'groupList', []),
    departmentOptions: get(details, 'departmentList', []),
    employeeOptions: get(details, 'employeeList', []),
    brokerOptions: get(details, 'brokerList', []),
    lobOption: get(details, 'lobList', []),
    salesforceCustomerUUID: get(details, 'salesforceCustomerUUID', ''),
    amsHeaderDefaults: get(details, 'amsHeaderDefaults', null),
  };
};

export const getValidNumber = (no: any) => (isNaN(Number(no)) ? no : Number(no));

export const checkAnnexPrefill = (stories: any, type: any) =>
  String(stories) !== '0' && stories !== null && SUBMIT_VALUES.includes(type);

export const getCarrierCompanyCode = (details: any) => {
  const name = details ? details.Name : '';
  return String(name).trim();
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const goToDashboard = () => {
  const {
    partner: { isProviderOneLogin },
  } = store.getState();
  if (!inIframe()) {
    history.push(DEFAULT_LOB_PATH);
    window.scrollTo(0, 0);
  } else {
    if (!isProviderOneLogin) {
      window.top.location = goToDashboardUrl();
    } else {
      store.dispatch(
        setHomeState({
          showError: true,
          notFoundError: true,
          expiredError: false,
        })
      );
      history.push('/error');
    }
  }
};

export const useBeforeFirstRender = cb => {
  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => setHasRendered(true), []);
  if (!hasRendered) {
    cb();
  }
};

export const orderLOBRates = (rates: Array<any>) => {
  const appointments: AppRedux.Appointments = {
    HOME: {},
    QUAKE: {},
    HOMEWARRANTY: {},
    CYBERSECURITY: {},
    AUTO: {},
    FLOOD: {},
    PET: {},
    LIFE: {},
    UMBRELLA: {},
  };
  Object.keys(appointments).forEach(key => {
    const lobKey = key as keyof AppRedux.Appointments;
    rates.forEach(rate => {
      if (rate?.type?.toLowerCase() === key?.toLowerCase()) {
        appointments[lobKey] = rate;
      }
    });
    if (!Object.keys(appointments[lobKey]).length) {
      delete appointments[lobKey];
    }
  });
  const result: Array<any> = [];
  Object.values(appointments).forEach(value => {
    result.push(value);
  });
  return result;
};

export const removeExistingRate = (rates: Array<any>, type: string) => {
  const index = rates.findIndex(rate => rate.type === type);

  if (index > -1) {
    rates.splice(index, 1);
  }
  return rates;
};

export const deleteDiffKeys = (object: object, targetObject: object) => {
  const differentKeys = [];
  Object.keys(object).forEach(key => {
    if (!Object.keys(targetObject).includes(key)) {
      differentKeys.push(key);
    }
  });

  const objectSameKeys = { ...omit(object, differentKeys) };

  return objectSameKeys;
};

export const getOrganizationCode = (common: any, home: any) => {
  let data = {
    orgCode: '',
    orgPresent: false,
  };
  if (!!common?.lineOfBusiness && common?.lineOfBusiness.toLowerCase() === INSURANCE_PRODUCTS[1]) {
    data.orgCode = !isEmpty(get(common.pconfig, 'pconfig.dale_config.code', ''))
      ? get(common.pconfig, 'pconfig.dale_config.code', '')
      : common?.pconfig?.dale_config?.code ?? '';
  } else {
    data.orgCode = get(home.pconfig, 'dale_config.code', '') ?? home?.code ?? '';
  }
  if (!!data?.orgCode) {
    const { orgPresent } = checkValueWithOrganization(
      get(common, 'AMSLOBDetails.divisionList', ''),
      data?.orgCode,
      'GLDivisionCode'
    );
    data.orgPresent = orgPresent;
  }
  return data;
};

export const getHerdIdFromUrl = () => {
  const search = history?.location?.search;
  const searchParams: any = !isEmpty(search) ? new URLSearchParams(search) : '';
  let herdId = '';
  if (searchParams) {
    herdId = searchParams?.get('herd_id') ?? searchParams?.get('id');
  }
  return { herdId, isHerdIdPresent: !isEmpty(herdId) };
};

export const checkProducerLicense = ({ pconfig, address, producerDetails }: any) => {
  const { state } = address;
  let shouldContinue = true;
  if (
    get(pconfig, 'dale_config.check_agent_license_compliance', false) &&
    producerDetails &&
    Array.isArray(producerDetails.producerLicensedStates) &&
    !producerDetails.producerLicensedStates.includes(state)
  ) {
    shouldContinue = false;
  }
  return shouldContinue;
};

// Utility function to determine changes between objects so we can make or not a new api call
export const differenceBetweenObjects = (object: any, base: any) => {
  function changes(object: any, base: any) {
    return transform(object, function (result: any, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
};

export const hasViolationChanged = ({
  field,
  value,
  list,
}: {
  field: string;
  value: string;
  list: Array<any>;
}) => {
  let hasChanged = false;

  list.forEach(violation => {
    if (violation[field] !== value) {
      hasChanged = true;
    }
  });

  return hasChanged;
};

export const convertStringToNumber = (value: string) => {
  const regex = /^[0-9]+$/;
  if (value.match(regex)) {
    return parseInt(value);
  } else {
    return value;
  }
};

export const password_regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

export const getCarrierDataByCarrierId = (id: any, list: any) => {
  return find(list, item => String(item.code) === String(id));
};

export const statusList = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];

export const themeList = [
  {
    label: 'Hippo Green',
    value: 'hippo-green',
  },
  {
    label: 'Navy Blue',
    value: 'navy-blue',
  },
  {
    label: 'Purplish Blue',
    value: 'purplish-blue',
  },
  {
    label: 'Sky Blue',
    value: 'sky-blue',
  },
];

// Format date in MM/DD/YYYY
export const formatDate = (date: any) => {
  let validDate = moment(date).format('MM/DD/YYYY');
  if (!moment(validDate, dateFormat, true).isValid()) {
    validDate = '-';
  }
  return validDate;
};

// Date sorting
export const dateSort = (a, b) => {
  let dateA = a;
  let dateB = b;
  if (dateA === HTML_EM) {
    dateA = 0;
  }
  if (dateB === HTML_EM) {
    dateB = 0;
  }
  dateA = new Date(dateA).getTime();
  dateB = new Date(dateB).getTime();
  return dateA - dateB;
};

// Sort by string value
export const stringSort = (a, b) => {
  a = a.replace(' ', '');
  b = b.replace(' ', '');
  return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
};

export const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

export const getSeperateQuoteList = (errorDetails: any, home: any) => {
  let errorQuotes = [];
  const otherQuotes: any = [];
  const otherErrorQuotes: any = [];
  if (
    get(errorDetails, 'display_errors_on_ui', false) &&
    !isEmpty(get(errorDetails, 'errors', ''))
  ) {
    errorQuotes = filter(errorDetails.errors, error => {
      return assignIn(error, { quoteError: true });
    });
  }
  if (home) {
    forEach(home.quoteList, (quote: any) => {
      if (quote.quoteError && quote) {
        otherErrorQuotes.push(quote);
      } else {
        otherQuotes.push(quote);
      }
    });
  }
  return {
    errorQuotes,
    otherQuotes,
    otherErrorQuotes,
  };
};

export const getAuthToken = () => {
  const state = store.getState(); //Access store
  return state?.auth?.authUser ? state.auth.authUser : localStorage.getItem('Authorization');
};

export const getThemeName = (data: any) => {
  let themeName = '';
  !!data &&
    !isEmpty(themeList) &&
    themeList.forEach((item: any) => {
      if (item?.value === data) {
        themeName = item?.label;
      }
    });
  return themeName;
};

export const loadQuotesOnRatePage = (annexPrefill: any, newAppointments: any) => {
  return (
    (!isEmpty(annexPrefill) &&
      (Object.keys(newAppointments).includes('HOME') ||
        Object.keys(newAppointments).includes('QUAKE'))) ||
    Object.keys(newAppointments).includes('HOMEWARRANTY') ||
    Object.keys(newAppointments).includes('CYBERSECURITY')
  );
};

export const goToDashboardUrl = () => {
  return `${
    window.location !== window.parent.location ? document.referrer : document.location.href
  }app/producer/dashboard` as any;
};

export const getHeaderOptions = (type: string) => ({
  headers: {
    'Content-Type': type,
    'Access-Control-Allow-Origin': '*',
  },
});

export const arrayToStringObject = (data: any) => {
  return !isEmpty(data)
    ? Object.keys(data)
        .map((item: any) => {
          return data[item];
        })
        .join(', ')
    : '';
};

export const downloadFileUrl = (fileUrl: any) => {
  var iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  document.body.appendChild(iframe);
  iframe.src = fileUrl;
};

export const orderLobs = (lobList: any) => {
  return INSURANCE_PRODUCTS.filter(lob => lobList.includes(lob));
};

export const getSelectedCarrierIndex = (carrierSelect: any, quoteList: any) => {
  return quoteList.findIndex(
    (el: any) => el?.carrier_id?.toString() === carrierSelect?.carrierId?.toString()
  );
};

export const checkForNonPremiumBoundedCarrier = (
  lob?: any,
  boundedQuote?: any,
  quoteList?: any
) => {
  return !!lob &&
    !isEmpty(boundedQuote) &&
    !isEmpty(boundedQuote[lob?.toLowerCase()]) &&
    !quoteList.find(quote => !!quote?.policyBounded || !!quote?.data?.policyBounded)
    ? boundedQuote[lob?.toLowerCase()]
    : '';
};

export const capitalizeEach = (sentence: string): string => {
  return sentence.split(' ').map(capitalize).join(' ');
};

export const getCarrierNameFromCarrierList = (lob: string, carrierId: any) => {
  const { common } = store.getState();
  let carrierName = '';
  let carrierList = [];
  if (!isEmpty(common?.AMSLOBDetails?.carrierList) && !isEmpty(lob)) {
    carrierList = common?.AMSLOBDetails?.carrierList[lob];
  }
  if (!isEmpty(carrierList) && !!carrierId) {
    carrierList?.forEach((item: any) => {
      if (item?.carrierId?.toString() === carrierId?.toString()) {
        carrierName = item.name;
      }
    });
  }
  return carrierName;
};

export const integrationTypeList = [
  { label: 'Annex', value: 'annex' },
  { label: 'Direct', value: 'direct' },
  { label: 'Ezlynx', value: 'ezlynx' },
];

export const isValidHttpUrl = (value: string): boolean => {
  const regex = new RegExp(
    '^(?:(?:http(?:s)?)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$'
  );

  const localhostRegex = new RegExp(/^http:\/\/\w+(\.\w+)*(:[0-9]+)?\/?$/);

  return regex.test(value) || localhostRegex.test(value);
};

export const getRequestedUrlFromCookie = () => {
  const cookies = new Cookies();
  return !isEmpty(cookies.get('requestedUrl')) ? cookies.get('requestedUrl') : '';
};

export const removeRequestedUrlFromCookie = () => {
  const cookies = new Cookies();
  cookies.remove('requestedUrl');
};

export const isAutoQuote = (appointments: any) => {
  return (
    appointments.includes('AUTO') &&
    !appointments.includes('HOME') &&
    !appointments.includes('QUAKE') &&
    !appointments.includes('FLOOD') &&
    !appointments.includes('HOMEWARRANTY') &&
    !appointments.includes('CYBERSECURITY')
  );
};

export const equalsIgnoreCase = (a: string, b: string) =>
  a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0;

export const isValidUrl = (value: string): boolean => {
  const regex =
    /^((ftp|http|https|smtp):\/\/)?(www.)?(?!.*(ftp|http|https|smtp|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
  return regex.test(value);
};

export const clearLobQuotes = () => {
  let lobQuotesToClear = [];
  let clearAutoInfo = false;
  let clearPetInfo = false;
  let clearLifeInfo = false;
  let clearUmbrellaInfo = false;
  const {
    home,
    quote,
    floodDetails,
    petDetails,
    partner,
    common,
    lifeDetails,
    quakeDetails,
    umbrellaDetails,
  } = store.getState();
  const { productType, personal_details, old_applicant_details } = home;
  let personalInformationKeys = [
    'personal_details.date_of_birth',
    'personal_details.education',
    'personal_details.email',
    'personal_details.first_name',
    'personal_details.industry',
    'personal_details.last_name',
    'personal_details.middle_name',
    'personal_details.occupation',
    'personal_details.phone_number',
  ];
  let addressKeys = [
    'address.city',
    'address.state',
    'address.street',
    'address.unit',
    'address.zip',
  ];
  if (!isEmpty(old_applicant_details)) {
    let requoteLobs = [];
    let isApplicantChange = false;
    let isPropertyTypeChange = false;
    let isDependencyValueChange = false;
    let appointmentKeys = Object.keys(partner?.appointments);
    let redirectToRatePage =
      appointmentKeys.includes(INSURANCE_BUSINESS_TYPES.HOME) ||
      appointmentKeys.includes(INSURANCE_BUSINESS_TYPES.QUAKE) ||
      appointmentKeys.includes(INSURANCE_BUSINESS_TYPES.HOMEWARRANTY) ||
      appointmentKeys.includes(INSURANCE_BUSINESS_TYPES.CYBERSECURITY);
    for (let key of personalInformationKeys) {
      let oldValue = get(old_applicant_details, key, null) ? get(old_applicant_details, key) : '';
      if (!isEmpty(oldValue) && typeof oldValue === 'string') {
        oldValue = oldValue.toLowerCase();
      }
      let newValue = get(home, key, '');
      if (!isEmpty(newValue) && typeof newValue === 'string') {
        newValue = newValue.toLowerCase();
      }
      if (oldValue !== newValue) {
        isApplicantChange = true;
        break;
      }
    }
    for (let key of addressKeys) {
      let oldValue = get(old_applicant_details, key, null) ? get(old_applicant_details, key) : '';
      if (!isEmpty(oldValue) && typeof oldValue === 'string') {
        oldValue = oldValue.toLowerCase();
      }
      let newValue = get(home, key, '');
      if (!isEmpty(newValue) && typeof newValue === 'string') {
        newValue = newValue.toLowerCase();
      }
      if (oldValue !== newValue) {
        isApplicantChange = true;
        isDependencyValueChange = true;
        break;
      }
    }
    const { first_name, last_name, date_of_birth, email, phone_number } =
      old_applicant_details?.personal_details;
    if (productType !== old_applicant_details?.productType) {
      isPropertyTypeChange = true;
    }
    if (
      isApplicantChange &&
      !isEmpty(petDetails?.petList) &&
      !petDetails?.isBreedError &&
      !petDetails?.isPolicyBounded
    ) {
      if (!isDependencyValueChange) {
        requoteLobs.push(INSURANCE_PRODUCTS[4]);
      } else if (isDependencyValueChange) {
        clearPetInfo = true;
        store.dispatch(storePetListData([]));
      }
    }
    if (
      isDependencyValueChange &&
      !umbrellaDetails?.isPolicyBounded &&
      (!isEmpty(umbrellaDetails?.quoteList) ||
        !isEmpty(umbrellaDetails?.quoteErrorList) ||
        !isEmpty(umbrellaDetails?.umbrellaInfo?.questionGroups))
    ) {
      clearUmbrellaInfo = true;
      lobQuotesToClear.push(INSURANCE_PRODUCTS[8]);
      store.dispatch(
        storeUmbrellaQuoteDetails({
          quoteList: [],
          quoteErrorList: [],
          umbrellaInfo: null,
          umbrellaQuestionsResponse: null,
          upcomingCarrierList: [],
        })
      );
    }
    let isFistNameLastNameChange: boolean = false;
    let isEmailPhoneNumberChanges: boolean = false;
    if (email !== personal_details?.email || phone_number !== personal_details?.phone_number) {
      isEmailPhoneNumberChanges = true;
    }
    if (first_name !== personal_details?.first_name || last_name !== personal_details?.last_name) {
      isDependencyValueChange = true;
      isFistNameLastNameChange = true;
    }
    let isChange = isApplicantChange || isPropertyTypeChange;
    let isHomePolicyBounded =
      !isEmpty(common?.policyBounded) && common?.policyBounded[INSURANCE_PRODUCTS[0]];
    if (
      !isHomePolicyBounded &&
      home?.herd_id &&
      isChange &&
      appointmentKeys.includes(INSURANCE_BUSINESS_TYPES.HOME)
    ) {
      store.dispatch(
        setHomeState({
          quoteList: [],
          quoteListError: [],
          upcomingCarrierList: [],
        })
      );
      lobQuotesToClear.push(LOB_PRODUCTS_MAPPING.home);
    }
    let isFloodPolicyBounded =
      !isEmpty(common?.policyBounded) && common?.policyBounded[INSURANCE_PRODUCTS[2]];
    if (
      !isFloodPolicyBounded &&
      floodDetails?.herdId &&
      isChange &&
      appointmentKeys.includes(INSURANCE_BUSINESS_TYPES.FLOOD)
    ) {
      lobQuotesToClear.push(INSURANCE_PRODUCTS[2]);
      store.dispatch(
        setFloodQuotes({
          quoteList: [],
          quoteErrorList: [],
          upcomingCarrierList: [],
        })
      );
    }
    let isQuakePolicyBounded =
      !isEmpty(common?.policyBounded) && common?.policyBounded[INSURANCE_PRODUCTS[3]];
    if (
      !isQuakePolicyBounded &&
      quakeDetails?.herdId &&
      isChange &&
      appointmentKeys.includes(INSURANCE_BUSINESS_TYPES.QUAKE)
    ) {
      store.dispatch(
        setQuakeQuotes({
          quoteList: [],
          upcomingCarrierList: [],
        })
      );
    }
    let isAutoPolicyBounded =
      !isEmpty(common?.policyBounded) && common?.policyBounded[INSURANCE_PRODUCTS[1]];
    if (
      !isAutoPolicyBounded &&
      quote?.herdId &&
      isApplicantChange &&
      appointmentKeys.includes(INSURANCE_BUSINESS_TYPES.AUTO)
    ) {
      lobQuotesToClear.push(INSURANCE_PRODUCTS[1]);
      if (quote?.quotesLoading) {
        store.dispatch(stopPolling());
        store.dispatch(setQuotesLoading(false));
      }
      store.dispatch(
        setAutoQuoteDetails({
          quotes: [],
          errors: [],
        })
      );
      if (!isDependencyValueChange) {
        requoteLobs.push(INSURANCE_PRODUCTS[1]);
      } else {
        clearAutoInfo = true;
        store.dispatch(clearVehiclesList());
        store.dispatch(clearDriverInfo());
        store.dispatch(clearAutoDataOnApplicantChanges());
        store.dispatch(clearInsurenaceDetails());
        store.dispatch(setIsVeriskFetched(false));
        store.dispatch(
          updateDriverName({
            first_name: personal_details?.first_name,
            last_name: personal_details?.last_name,
            date_of_birth: personal_details?.date_of_birth,
            industry: personal_details?.industry,
            occupation: personal_details?.occupation,
            education: personal_details?.education,
          } as any)
        );
      }
    }
    if (
      !petDetails?.isPolicyBounded &&
      petDetails?.herdId &&
      isApplicantChange &&
      appointmentKeys.includes(INSURANCE_BUSINESS_TYPES.PET)
    ) {
      lobQuotesToClear.push(INSURANCE_PRODUCTS[4]);
      store.dispatch(
        setPetQuotes({
          quoteList: [],
          upcomingCarrierList: [],
        })
      );
      !requoteLobs.includes(INSURANCE_PRODUCTS[4]) &&
        store.dispatch(setPetBreedsDetails({ cat: {}, dog: {} }));
    }
    if (
      lifeDetails?.herdId &&
      date_of_birth !== personal_details?.date_of_birth &&
      appointmentKeys.includes(INSURANCE_BUSINESS_TYPES.LIFE)
    ) {
      requoteLobs.push(INSURANCE_PRODUCTS[6]);
      !redirectToRatePage && lobQuotesToClear.push(INSURANCE_PRODUCTS[6]);
      store.dispatch(
        storeLifeQuoteDetails({
          quoteList: [],
          quoteErrorList: [],
          upcomingCarrierList: [],
        })
      );
    }
    if (
      umbrellaDetails?.herdId &&
      appointmentKeys.includes(INSURANCE_BUSINESS_TYPES.UMBRELLA) &&
      (date_of_birth !== personal_details?.date_of_birth ||
        isFistNameLastNameChange ||
        isEmailPhoneNumberChanges)
    ) {
      !clearUmbrellaInfo && requoteLobs.push(INSURANCE_PRODUCTS[8]);
      !redirectToRatePage && lobQuotesToClear.push(INSURANCE_PRODUCTS[8]);
      store.dispatch(
        storeUmbrellaQuoteDetails({
          quoteList: [],
          quoteErrorList: [],
          upcomingCarrierList: [],
        })
      );
    }
    if (!isEmpty(requoteLobs)) {
      store.dispatch(updateRequoteProducts(requoteLobs));
    }
    store.dispatch(
      setHomeState({
        old_applicant_details: {},
      })
    );
    let selectedCarrierList = !!common?.selectedCarriers ? common.selectedCarriers : '';
    if (sessionStorage.selectedQuoteList && lobQuotesToClear.length) {
      let selectedQuotes = JSON.parse(sessionStorage.selectedQuoteList);
      let selectedQuoteList = [];
      lobQuotesToClear.forEach((lob: any) => {
        lob = lob === EARTH_QUAKE_LOB ? INSURANCE_PRODUCTS[3] : lob;
        selectedQuotes = removeExistingRate(selectedQuotes, lob.toUpperCase());
        !isEmpty(selectedQuotes) && selectedQuoteList.push(selectedQuotes);
        if (!!selectedCarrierList[lob]) {
          selectedCarrierList[lob] = {};
        }
      });
      store.dispatch(updateSelectedCarriers(selectedCarrierList));
      // sessionStorage.selectedQuoteList = !isEmpty(JSON.stringify(selectedQuoteList)) ? JSON.stringify(selectedQuoteList) : [];
    }
  }
  return { lobQuotesToClear, clearAutoInfo, clearPetInfo, clearLifeInfo, clearUmbrellaInfo };
};

export const disabledApplicantFormField = () => {
  let isDisablePropertyTypeAndDeductible: boolean = false;
  let isDisableFormField: boolean = false;
  const { common, petDetails } = store.getState();
  const { policyBounded } = common;
  const disableApplicant = allowEditingQuote();
  if (!isEmpty(policyBounded) || !!petDetails?.isPolicyBounded || disableApplicant?.applicant) {
    if (
      !!policyBounded?.auto ||
      !!policyBounded?.pet ||
      !!policyBounded?.home ||
      !!policyBounded?.earthquake ||
      !!policyBounded?.flood ||
      !!policyBounded?.quake ||
      !!petDetails?.isPolicyBounded ||
      disableApplicant?.applicant
    ) {
      isDisableFormField = true;
      isDisablePropertyTypeAndDeductible = true;
    }
    isDisablePropertyTypeAndDeductible =
      disableApplicant?.applicant ||
      !!policyBounded?.home ||
      !!policyBounded?.earthquake ||
      !!policyBounded?.flood
        ? true
        : false;
  }
  return {
    isDisablePropertyTypeAndDeductible,
    isDisableFormField,
  };
};

export const checkSpouseMissing = (driversList: any) => {
  let missingSpouse: boolean = false;
  let driverDetails: any = null;
  for (let index = 0; index < driversList.length; index++) {
    if (
      driversList[index].isPrimaryDriver &&
      !driversList[index].isSpouse &&
      driversList[index].marital_status === 'Married'
    ) {
      let getDriverSpouse = driversList.find(
        (driver: any) => driver.duid === driversList[index].spouseId && driver.isSpouse
      );
      if (!getDriverSpouse) {
        driverDetails = driversList[index];
        missingSpouse = true;
        break;
      }
    }
  }
  return { missingSpouse, driverDetails };
};

export const getSelectedQuote = (lob: string) => {
  const selectedQuoteList = sessionStorage.selectedQuoteList
    ? JSON.parse(sessionStorage.selectedQuoteList)
    : [];
  let selectedQuote: any = null;
  if (!isEmpty(selectedQuoteList) && !isEmpty(lob)) {
    let selectedLob = lob === EARTH_QUAKE_LOB ? INSURANCE_PRODUCTS[3] : lob;
    selectedQuote = selectedQuoteList.find((item: any) => {
      let newData = '';
      if (!!item?.type && selectedLob && item?.type.toLowerCase() === selectedLob.toLowerCase()) {
        newData = item?.quote;
      }
      return newData;
    });
  }
  return selectedQuote;
};

export const allowEditingQuote = () => {
  const disableQuoteEditing = {
    applicant: false,
    auto: false,
    pet: false,
    flood: false,
    life: false,
    umbrella: false,
  };
  const {
    common,
    home,
    partner: { appointments },
    quote: { quoteList, quoteErrorList },
    floodDetails,
    petDetails,
    quakeDetails,
    lifeDetails,
    umbrellaDetails,
  } = store.getState();
  if (!common?.allowEditQuotes && !isEmpty(appointments)) {
    const appointmentsKeys = Object.keys(appointments);
    if (appointmentsKeys.includes('AUTO') && (!isEmpty(quoteList) || !isEmpty(quoteErrorList))) {
      disableQuoteEditing.auto = true;
    }
    if (appointmentsKeys.includes('FLOOD') && !isEmpty(floodDetails?.quoteList)) {
      disableQuoteEditing.flood = true;
    }
    if (appointmentsKeys.includes('PET') && !isEmpty(petDetails?.quoteList)) {
      disableQuoteEditing.pet = true;
    }
    if (
      appointmentsKeys.includes('LIFE') &&
      (!isEmpty(lifeDetails?.quoteList) || !isEmpty(lifeDetails?.quoteErrorList))
    ) {
      disableQuoteEditing.life = true;
    }
    if (
      appointmentsKeys.includes('UMBRELLA') &&
      (!isEmpty(umbrellaDetails?.quoteList) || !isEmpty(umbrellaDetails?.quoteErrorList))
    ) {
      disableQuoteEditing.umbrella = true;
    }
    let applicantEdit =
      (appointmentsKeys.includes('AUTO') && !disableQuoteEditing.auto) ||
      (appointmentsKeys.includes('FLOOD') && !disableQuoteEditing.flood) ||
      (appointmentsKeys.includes('PET') && !disableQuoteEditing.pet) ||
      (appointmentsKeys.includes('LIFE') && !disableQuoteEditing.life) ||
      (appointmentsKeys.includes('UMBRELLA') && !disableQuoteEditing.umbrella) ||
      (appointmentsKeys.includes('HOME') &&
        (isEmpty(home?.productType) ||
          (isEmpty(home?.quoteList) && isEmpty(home?.quoteListError)))) ||
      (appointmentsKeys.includes('QUAKE') &&
        (isEmpty(home?.productType) ||
          (isEmpty(quakeDetails?.quoteList) && !isEmpty(floodDetails?.annexPrefill))));
    if (!applicantEdit) {
      disableQuoteEditing.applicant = true;
    }
  }
  return disableQuoteEditing;
};

export const propertyTypeMapping: any = {
  'ho-3': 'n',
  'ho-4': 'o',
  'ho-5': 'h',
  'ho-6': 'y',
  'dp-1': 'x',
  'dp-3': 'z',
  ho3: 'n',
  ho4: 'o',
  ho5: 'h',
  ho6: 'y',
  dp1: 'x',
  dp3: 'z',
};

export const updateApplicantinfo = (applicant: any, address: any, previousAddress?: any) => {
  const preferredDeductible = !!applicant?.preferredDeductible
    ? applicant?.preferredDeductible?.toString()
    : '';
  const residenceType = !!applicant?.residenceType ? applicant?.residenceType : '';
  let applicantDetails = {
    first_name: !isEmpty(applicant?.first_name) ? applicant?.first_name : '',
    last_name: !isEmpty(applicant?.last_name) ? applicant?.last_name : '',
    date_of_birth: !isEmpty(applicant?.date_of_birth) ? applicant?.date_of_birth : '',
    occupation: !isEmpty(applicant?.occupation) ? applicant?.occupation : '',
    industry: !isEmpty(applicant?.industry) ? applicant?.industry : '',
    education: !isEmpty(applicant?.education) ? applicant?.education : '',
    preferredDeductible,
    residenceType,
  };
  const homeData: any = {
    personal_details: Object.assign(applicantDetails, {
      email: !isEmpty(applicant?.email) ? applicant?.email : '',
      is_condo: !isEmpty(applicant?.is_condo) ? applicant?.is_condo : '',
      middle_name: !isEmpty(applicant?.middle_name) ? applicant?.middle_name : '',
      phone_number: !isEmpty(applicant?.phone_number) ? applicant?.phone_number : '',
      product: !isEmpty(applicant?.product) ? applicant?.product : '',
      preferredDeductible,
      residenceType,
    }),
    address: address,
    previousAddress: !isEmpty(previousAddress) ? previousAddress : null,
    productType: applicant?.product ? applicant?.product.replace('-', '') : '',
    is_condo: propertyTypeMapping[applicant?.product],
  };
  store.dispatch(updateApplicantDetails(applicantDetails));
  store.dispatch(updateApplicantAddress(address));
  store.dispatch(updateDriverName(applicantDetails as any));
  store.dispatch(setHomeState(homeData));
};

export const createPageTracking = (pageTracking: any, selectedLobs: any) => {
  let {
    partner: { appointments },
  } = store.getState();
  appointments = isEmpty(appointments) ? selectedLobs : appointments;
  pageTracking = !isEmpty(pageTracking) ? pageTracking : {};
  Object.keys(appointments).forEach(key => {
    let appointmentKey = key?.toLowerCase();
    if (!Object.keys(pageTracking).includes(appointmentKey)) {
      pageTracking[appointmentKey] = DEFAULT_PAGE_TRACKING[appointmentKey];
    }
  });
  store.dispatch(setPageTracking(pageTracking));
  return pageTracking;
};

export const isApplicantDataRequired = () => {
  let {
    home,
    applicant: { previousAddress, isMovedInPastMonths, isApplicantSubmittedOnceForAuto },
  } = store.getState();
  let isPreviousAddressRequired = false;
  if (getIsLobIncludesInAppointments(LOB_PRODUCTS_MAPPING.AUTO)) {
    isPreviousAddressRequired = !isApplicantSubmittedOnceForAuto;
    if (!isPreviousAddressRequired && shouldApplicantHasPreviousAddress()) {
      isPreviousAddressRequired = isMovedInPastMonths && isEmptyValues(previousAddress);
    }
  }
  return (
    isEmpty(home?.personal_details?.education) ||
    isEmpty(home?.personal_details?.industry) ||
    isEmpty(home?.personal_details?.occupation) ||
    isPreviousAddressRequired
  );
};

export const setProductsPriority = (appointments: any) => {
  let { partner } = store.getState();
  let appointmentKeys: array<any> = !isEmpty(partner?.appointments)
    ? Object.keys(partner?.appointments)
    : [];
  let redirectTo = STEPPER_KEYS_MAPPING.RATE_PAGE;
  let isRedirectToRate =
    appointments.includes('HOME') ||
    appointments.includes('QUAKE') ||
    appointments.includes('HOMEWARRANTY') ||
    appointments.includes('CYBERSECURITY') ||
    (appointments.includes('FLOOD') &&
      (appointmentKeys.includes('HOME') ||
        appointmentKeys.includes('QUAKE') ||
        appointmentKeys.includes('HOMEWARRANTY') ||
        appointmentKeys.includes('CYBERSECURITY')));
  if (!isRedirectToRate) {
    if (appointments.includes('FLOOD')) {
      redirectTo = STEPPER_KEYS_MAPPING.FLOOD_INFO;
    } else if (appointments.includes('AUTO')) {
      redirectTo = STEPPER_KEYS_MAPPING.VEHICLE_INFO;
    } else if (appointments.includes('PET')) {
      redirectTo = STEPPER_KEYS_MAPPING.PET_INFO;
    } else if (appointments.includes('LIFE')) {
      redirectTo = STEPPER_KEYS_MAPPING.LIFE_INFO;
    } else if (appointments.includes('UMBRELLA')) {
      redirectTo = STEPPER_KEYS_MAPPING.UMBRELLA_INFO;
    }
  }
  store.dispatch(setLobPriority(redirectTo));
};

export const onBridgeToCarrierClick = (quote: any, lobType: string, isRequestBind: boolean) => {
  const { common, petDetails, lifeDetails, cyberSecurityDetails } = store.getState();
  lobType = !!lobType && lobType?.toLowerCase();
  const sortedPremiumDetails = sortBy(quote.premiumDetails, ['premium']);
  let selectedPlan = SILVER;
  const payload = {
    carrierId: quote?.carrier_id,
  };
  const isPetQuote = lobType === INSURANCE_PRODUCTS[4];
  const isCyberQuote = lobType === INSURANCE_PRODUCTS[7];
  const isFloodQuote = lobType === INSURANCE_PRODUCTS[2];
  const firstPremiumDetails = quote?.premiumDetails[0];
  const selectedQuoteDetails = getSelectedQuote(lobType);
  const isSelectedQuote =
    quote?.carrier_id?.toString() === selectedQuoteDetails?.quote?.carrier_id?.toString();
  let quoteId = firstPremiumDetails?.quote_id || '';
  if (!isEmpty(common?.selectedCarriers[lobType])) {
    let selectedPremium =
      sortedPremiumDetails?.length &&
      sortedPremiumDetails.find(
        el => Number(el?.premium) === Number(common?.selectedCarriers[lobType]?.premium)
      );
    selectedPlan =
      isSelectedQuote && !isEmpty(selectedPremium) ? selectedPremium?.plan : selectedPlan;
    quoteId =
      (isSelectedQuote && !isEmpty(selectedPremium)
        ? selectedPremium?.quote_id
        : firstPremiumDetails?.quote_id) || '';
  }
  let premiumValue =
    !!selectedQuoteDetails?.quote?.price &&
    quote?.carrier_id?.toString() === selectedQuoteDetails?.quote?.carrier_id?.toString()
      ? selectedQuoteDetails?.quote?.price
      : firstPremiumDetails?.premium;
  let trackingPayload = {
    carrierId: quote?.carrier_id,
    carrierName: quote.carrier_name || quote.name,
    premium: premiumValue,
  };
  let getQuoteCount = getQuoteAndErrQuoteCount(lobType);
  let quoteDetails = getCarrierAndPremiumRank(lobType, quote?.carrier_id);
  let isHeapAnalyticsEnabled: boolean = !!window.heap && getIsHeapAnalyticsEnabled();
  if (isHeapAnalyticsEnabled) {
    window?.heap?.track(QUOTING_STEPS_PAGES_NAME?.RATES_BRIDGE_TO_CARRIER, {
      [HEAP_LABELS?.PRODUCT]: startCase(lobType),
      [HEAP_LABELS.NO_OF_PRODUCT_QUOTES]: getQuoteCount.quoteCount,
      [HEAP_LABELS.NO_OF_PRODUCT_NON_RETURN_QUOTE]: getQuoteCount.errorCount,
      [PREMIUM]: !!quote?.price ? quote.price?.toString() : null,
      [HEAP_LABELS.RESULT_RANK]: quoteDetails?.quoteRank || 0,
      [HEAP_LABELS.PREMIUM_RANK]: quoteDetails?.premiumRank || 0,
      [CARRIER]: quote?.carrierName || quote?.name || quote?.carrier_name || '',
      [HEAP_LABELS?.PRODUCT_COUNT]: getQuoteCount?.productCount,
    });
  }
  if (isPetQuote) {
    payload.herdId = petDetails?.herdId ?? '';
    payload.plan = selectedPlan;
    !isRequestBind && store.dispatch(getBridgeToCarrierDetailsForPet(payload));
  } else if (isFloodQuote) {
    payload.lob = lobType;
    payload.quoteId = quoteId;
    !isRequestBind && store.dispatch(getBridgeToCarrierDetailsForFlood(payload));
  } else {
    payload.premium = premiumValue;
    payload.herdId = isCyberQuote ? cyberSecurityDetails?.herdId : lifeDetails?.herdId;
    if (isCyberQuote) {
      payload.lob = CYBER_SECURITY;
      payload.coverage =
        (isSelectedQuote && selectedQuoteDetails?.quote?.selectedPremiumType) ||
        firstPremiumDetails?.coverageType ||
        '';
      const carrierSelectedPlan =
        (isSelectedQuote && selectedQuoteDetails?.quote?.selectedPlan) || CYBER_DEFAULT_PLAN;
      const groupMapping = {
        pricingMeMyHousehold: 'Single',
        pricingMeMyHouseholdMySharedWorld: 'Group',
      };
      const coverageMapping = {
        essential: 'Plan1',
        premium: 'Plan2',
        theWorks: 'Plan3',
      };
      payload.selectedPlan = coverageMapping[payload.coverage] || '';
      payload.selectedGroup = groupMapping[carrierSelectedPlan] || '';
      !isRequestBind && store.dispatch(getBridgeToCarrierDetailsForCyber(payload));
    } else {
      payload.lob = lobType;
      payload.coverage =
        isSelectedQuote && selectedQuoteDetails?.quote?.selectedPremiumType
          ? selectedQuoteDetails?.quote?.selectedPremiumType
          : firstPremiumDetails?.coverage || '';
      payload.term =
        isSelectedQuote && selectedQuoteDetails?.quote?.term
          ? selectedQuoteDetails?.quote?.term
          : firstPremiumDetails?.term || '';
      !isRequestBind && store.dispatch(getBridgeToCarrierDetailsForLife(payload));
    }
  }
  !isRequestBind && store.dispatch(trackCarrierBridging({ ...trackingPayload }));
  return payload;
};

export const getIsHeapAnalyticsEnabled = () => {
  const {
    home: { pconfig },
    common,
  } = store.getState();
  let isHeapAnalyticsEnabled: boolean =
    !!get(pconfig, 'dale_config.heap_analytics_enabled', false) ||
    !!get(pconfig, 'pconfig.dale_config.heap_analytics_enabled', false) ||
    !!get(common?.pconfig, 'pconfig.dale_config.heap_analytics_enabled', false) ||
    !!get(common?.pconfig, 'dale_config.heap_analytics_enabled', false) ||
    false;
  return isHeapAnalyticsEnabled;
};

export const getHeapIdentity = () => {
  const cookies = new Cookies();
  let heapIdentity = !isEmpty(cookies.get(HEAP_IDENTITY))
    ? (cookies.get(HEAP_IDENTITY) as string)
    : v4();
  return heapIdentity;
};

export const getQuoteListOfLob = (lob: string) => {
  const {
    cyberSecurityDetails,
    floodDetails,
    home,
    homeQuoteDetails,
    homeWarrantyDetails,
    lifeDetails,
    petDetails,
    quote,
    quakeDetails,
    umbrellaDetails,
  } = store.getState();

  lob = lob?.toLowerCase() || '';
  const lobMapping: Record<string, any> = {
    [INSURANCE_PRODUCTS[0]]: home,
    [INSURANCE_PRODUCTS[1]]: quote,
    [INSURANCE_PRODUCTS[2]]: floodDetails,
    [INSURANCE_PRODUCTS[3]]: quakeDetails,
    [EARTH_QUAKE_LOB]: quakeDetails,
    [INSURANCE_PRODUCTS[4]]: petDetails,
    [INSURANCE_PRODUCTS[5]]: homeWarrantyDetails,
    [INSURANCE_PRODUCTS[6]]: lifeDetails,
    [INSURANCE_PRODUCTS[7]]: cyberSecurityDetails,
    [INSURANCE_PRODUCTS[8]]: umbrellaDetails,
  };

  let lobQuote = lobMapping[lob] || null;

  if (lob === LineOfBusiness.HOME && isEnabledPRSv2Flow()) {
    lobQuote = { ...lobQuote, quoteList: homeQuoteDetails?.quotes };
  }

  const isQuoteListAndErrorList = [
    INSURANCE_PRODUCTS[1],
    INSURANCE_PRODUCTS[6],
    INSURANCE_PRODUCTS[7],
    INSURANCE_PRODUCTS[8],
  ].includes(lob) || (!isEnabledPRSv2Flow() && lob === LineOfBusiness.HOME);

  let quoteList: Array<any> = [];

  if (lobQuote) {
    const quoteListData = lobQuote?.quoteList || [];
    const errorList = !isEmpty(lobQuote?.quoteErrorList)
      ? lobQuote?.quoteErrorList
      : !isEmpty(lobQuote?.quoteListError)
      ? lobQuote?.quoteListError
      : [];

    quoteList = isQuoteListAndErrorList
      ? [...quoteListData, ...errorList]
      : quoteListData;
  }

  return quoteList;
};

export const getQuoteStatus = (quote: unknown) => {
  let quoteStatus: string = quote?.status || quote?.Status || '';
  return !isEmpty(quoteStatus) ? quoteStatus?.toLowerCase() : '';
};

export const errorStatusList: string[] = ['failed', 'failed waiting'];

export const getQuoteAndErrQuoteCount = (lob: string) => {
  const {
    partner: { appointments },
  } = store.getState();
  let errorCount: number = 0;
  let quoteCount: number = 0;
  let productCount: number = 0;
  let quoteList: array<any> = getQuoteListOfLob(lob);
  if (!isEmpty(quoteList)) {
    quoteList.forEach((quote: any) => {
      if (!!quote?.quoteError || errorStatusList.includes(getQuoteStatus(quote))) {
        errorCount = errorCount + 1;
      } else {
        quoteCount = quoteCount + 1;
      }
    });
  }
  productCount = Object.keys(appointments)?.length || 0;
  return {
    errorCount,
    quoteCount,
    productCount,
  };
};

export const getCarrierAndPremiumRank = (lob: string, carrierId?: any, premium?: any) => {
  let quoteList: array<any> = getQuoteListOfLob(lob);
  let quoteRank: any = null;
  let premiumRank: any = null;
  let premiumList: array<any> = [];
  if (!isEmpty(quoteList) && carrierId) {
    quoteList.forEach((quote: any, index: number) => {
      const quoteCarrierId = quote?.carrier_id?.toString() ?? quote?.carrierId?.toString() ?? ''
      if (!isEmpty(quoteCarrierId) && quoteCarrierId === carrierId?.toString()) {
        quoteRank = index + 1;
      }
      const isErrorQuote =
        !!quote?.quoteError ??
        (!isEmpty(quote?.status) && quote.status.toLowerCase() === FAILED) ??
        (!isEmpty(quote?.Status) && quote.Status.toLowerCase() === FAILED) ??
        !!quote?.error
          ? true
          : false;
      if (!isErrorQuote) {
        if (!quote?.premiumDetails) {
          const price = quote?.price || quote?.data?.price;
          if (price) {
            premiumList.push(quote?.price);
          }
        } else {
          quote?.premiumDetails.forEach((premiumData: any) => {
            if (!!premiumData?.premium) {
              premiumList.push(premiumData?.premium);
            }
          });
        }
      }
    });
  }
  premiumRank = premium
    ? premiumList
        .sort(function (a, b) {
          return a - b;
        })
        .findIndex((details: any) => parseInt(details) === parseInt(premium)) + 1
    : 1;
  return { quoteRank, premiumRank };
};

export const setHeapProjectId = (details?: any, isView?: boolean) => {
  try {
    const {
      partner: { producerDetails },
      home: { pconfig },
      common,
    } = store.getState();
    let isHeapAnalyticsEnabled: boolean =
      !!details?.data?.dale_config?.heap_analytics_enabled ||
      !!details?.heap_analytics_enabled ||
      !!get(pconfig, 'dale_config.heap_analytics_enabled', false) ||
      !!get(pconfig, 'pconfig.dale_config.heap_analytics_enabled', false) ||
      !!get(common?.pconfig, 'pconfig.dale_config.heap_analytics_enabled', false) ||
      !!get(common?.pconfig, 'dale_config.heap_analytics_enabled', false) ||
      false;
    let heapProjectId: string =
      details?.data?.dale_config?.heap_project_id ||
      details?.heap_project_id ||
      get(pconfig, 'dale_config.heap_project_id', '') ||
      get(pconfig, 'pconfig.dale_config.heap_project_id', '') ||
      get(common?.pconfig, 'pconfig.dale_config.heap_project_id', '') ||
      get(common?.pconfig, 'dale_config.heap_project_id', '') ||
      '';
    let heapId: any = getHeapIdentity();
    let heapIdentity: any = !isEmpty(producerDetails?.producerCode)
      ? producerDetails?.producerCode
      : heapId;
    let isViewDetails = !!isView ? window.heap.load : true;
    if (isHeapAnalyticsEnabled && heapProjectId && isViewDetails) {
      store.dispatch(setHeapDetailsLoaded(true));
      window.heap.load(heapProjectId, {
        secureCookie: true,
      });
      window.heap.identify(heapIdentity);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const trackFirstLoadQuoteInHeap = () => {
  try {
    const {
      partner: { appointments },
    } = store.getState();
    let isHeapAnalyticsEnabled: boolean = !!window.heap && getIsHeapAnalyticsEnabled();
    if (isHeapAnalyticsEnabled) {
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.RATES_FIRST_QUOTE, {
        [HEAP_LABELS?.PRODUCT_COUNT]: Object.keys(appointments)?.length || 0,
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const trackLastLoadQuoteInHeap = (lob: string) => {
  try {
    const {
      partner: { appointments },
    } = store.getState();
    const isHeapAnalyticsEnabled: boolean = !!window.heap && getIsHeapAnalyticsEnabled();
    if (isHeapAnalyticsEnabled) {
      const quoteCountDetails: any = getQuoteAndErrQuoteCount(lob);
      const productKey = PRODUCT_MAPPING[lob?.toUpperCase()]
        ? PRODUCT_MAPPING[lob?.toUpperCase()].toLowerCase()
        : startCase(lob?.toLowerCase());
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.RATES_LAST_QUOTE, {
        [HEAP_LABELS?.PRODUCT_COUNT]: Object.keys(appointments)?.length || 0,
        [`${HEAP_LABELS?.NO_OF_QUOTES} ${!isEmpty(lob) ? `For ${productKey} Product` : ''}`]:
          quoteCountDetails?.quoteCount || 0,
        [`${HEAP_LABELS?.NO_OF_NON_QUOTES} ${!isEmpty(lob) ? `For ${productKey} Product` : ''}`]:
          quoteCountDetails?.errorCount || 0,
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const formatDob = (dob: string) => {
  return dob ? moment(dob, dateFormat).format(dateFormat) : '';
};

export const productList: array<string> = ['HO3', 'HO4', 'HO5', 'HO6', 'DP1', 'DP3'];

export const hideStepperBackButton = (productKey: string) => {
  const { petDetails, partner, common, insurance, lifeDetails, floodDetails } = store.getState();
  let hideBackButton = false;
  const appointmentKeys = Object.keys(partner?.appointments);
  if (
    productKey === INSURANCE_BUSINESS_TYPES.LIFE ||
    productKey === INSURANCE_BUSINESS_TYPES.UMBRELLA
  ) {
    if (appointmentKeys.includes('PET')) {
      hideBackButton = petDetails?.isPolicyBounded || isEmpty(petDetails?.petList);
    } else if (appointmentKeys.includes('AUTO')) {
      let isAutoPolicyBounded =
        !isEmpty(common?.policyBounded) && common?.policyBounded[INSURANCE_PRODUCTS[1]];
      hideBackButton = isAutoPolicyBounded || isEmpty(insurance?.residency_status);
    } else if (appointmentKeys.includes('FLOOD')) {
      let isFloodPolicyBounded =
        !isEmpty(common?.policyBounded) && common?.policyBounded[INSURANCE_PRODUCTS[2]];
      hideBackButton =
        isFloodPolicyBounded ||
        !floodDetails?.isFloodInfoRequired;
    } else if (
      productKey === INSURANCE_BUSINESS_TYPES.UMBRELLA &&
      appointmentKeys.includes('PET')
    ) {
      hideBackButton = isEmpty(lifeDetails?.petList);
    }
  }
  return hideBackButton;
};

export const getUniqueQuotes = (list: Array<any>, oldList: Array<any>) => {
  let updatedList: Array<any> = [];
  let newQuoteErrorList: Array<any> = [];
  if (!isEmpty(list)) {
    newQuoteErrorList = filter(
      list,
      quote =>
        !find(oldList, {
          carrier_id: quote.carrier_id,
          carrier_name: quote.carrier_name,
        }) && !find(oldList, { CarrierId: quote.CarrierId })
    );
    updatedList = [...oldList, ...newQuoteErrorList];
  }
  return updatedList;
};

export const getUpdatedQuoteList = (state: QuoteState, payload: any) => {
  const { error_details } = payload;
  const { errors } = error_details;
  let oldQuoteErrorList = [...state.quoteErrorList];
  if (!isEmpty(errors)) {
    oldQuoteErrorList = getUniqueQuotes(errors, oldQuoteErrorList);
  }
  return oldQuoteErrorList;
};

export const getHerdId = () => {
  const {
    home,
    quote,
    petDetails,
    floodDetails,
    homeWarrantyDetails,
    lifeDetails,
    cyberSecurityDetails,
    quakeDetails,
    umbrellaDetails,
  } = store.getState();
  return (
    floodDetails?.herdId ||
    quakeDetails?.herdId ||
    home?.herd_id ||
    quote?.herdId ||
    petDetails?.herdId ||
    homeWarrantyDetails?.herdId ||
    lifeDetails?.herdId ||
    cyberSecurityDetails?.herdId ||
    umbrellaDetails?.herdId ||
    ''
  );
};

export const homeProductList: array<string> = ['DP1', 'DP3', 'HO3', 'HO5', 'HO6'];

export const showEoiFileUpload = (eoiLob?: string) => {
  const {
    common: { AMSLOBDetails, adhocOrganizationDetails, sfdcOrganizationDetails, isLOBAMSDetails },
  } = store.getState();
  let isShowEoiFileUpload: boolean = false;
  let eoiUploadRequired: any = null;
  if (!isEmpty(eoiLob) && eoiLob?.toLowerCase() === INSURANCE_PRODUCTS[3]) {
    eoiLob = EARTH_QUAKE_LOB?.toUpperCase();
  }
  if (!isEmpty(eoiLob) && eoiLob?.toLowerCase() === UMBRELLA_LOB_CODE) {
    eoiLob = INSURANCE_PRODUCTS[8];
  }
  if (!isEmpty(sfdcOrganizationDetails)) {
    eoiUploadRequired =
      !isEmpty(sfdcOrganizationDetails?.eoiUploadRequired) &&
      sfdcOrganizationDetails?.eoiUploadRequired;
  } else {
    eoiUploadRequired =
      !isEmpty(adhocOrganizationDetails?.eoiUploadRequired) && !!isLOBAMSDetails
        ? adhocOrganizationDetails?.eoiUploadRequired
        : AMSLOBDetails?.eoiUploadRequired;
  }
  if (!isEmpty(eoiUploadRequired) && !isEmpty(eoiLob)) {
    isShowEoiFileUpload = !!eoiUploadRequired[eoiLob?.toUpperCase()] ? true : false;
  }
  return !!isShowEoiFileUpload;
};

export const enableAMSFormField = (item: any) => {
  const { common } = store.getState();
  const { addressDetails } = common?.AMSDetails;
  let isEnbleFomField: boolean = false;
  if (!isEmpty(item) && item === PHONE_NUMBER_KEY && isEmpty(common?.AMSDetails?.phoneNumber)) {
    isEnbleFomField = true;
  }
  if (
    !isEmpty(item) &&
    item === ADDRESS &&
    isEmpty(addressDetails?.city) &&
    isEmpty(addressDetails?.state) &&
    isEmpty(addressDetails?.zip) &&
    isEmpty(addressDetails?.county) &&
    isEmpty(addressDetails?.unit)
  ) {
    isEnbleFomField = true;
  }
  return isEnbleFomField;
};

export const getAnnualPremiumValue = (quote: any) => {
  const termLabel = quote?.term_lable ?? quote?.data?.termLabel ?? '';
  const priceDetails = quote?.price ?? quote?.data?.price ?? 0;
  let premiumData: any = {
    term: !isEmpty(termLabel) && termLabel === POLICY_TERMS[1]?.label ? 2 : 1,
    price: priceDetails ? parseFloat(priceDetails) : 0,
  };
  if (!isEmpty(quote?.premiumDetails) && priceDetails) {
    quote?.premiumDetails.forEach((item: any) => {
      let termValue = 1;
      if (parseInt(item?.premium) === parseInt(quote?.price)) {
        if (item?.termLabel === POLICY_TERMS[2]?.label || parseInt(item?.term) === 1) {
          termValue = 12;
        } else if (item?.termLabel === POLICY_TERMS[3]?.label || parseInt(item?.term) === 3) {
          termValue = 4;
        } else if (item?.termLabel === POLICY_TERMS[1]?.label || parseInt(item?.term) === 6) {
          termValue = 2;
        } else {
          termValue = 1;
        }
        premiumData = {
          term: termValue,
          price: parseFloat(item?.premium),
        };
      }
    });
  }
  return premiumData;
};

export const getPremiumTerm = (term: any) => {
  const premiumTermMapping: any = {
    1: MONTHLY,
    6: SEMI_ANNUALLY,
    12: ANNUALLY,
  };
  return premiumTermMapping[term] || `${term} Years`;
};

export const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif';

export const getSfdcPolicyHeadersDetails = () => {
  store.dispatch(fetchSFDCLOBDetails());
};

export const getOrganizationDetails = (organizationCode: string) => {
  const { home } = store.getState();
  const { code, pconfig } = home;
  if (
    !isEmpty(organizationCode) &&
    (code !== organizationCode || pconfig?.code !== organizationCode)
  ) {
    store.dispatch(
      fetchPconfig({
        code: organizationCode,
      })
    );
  }
};

export const getNestedTernaryData = (condition: boolean, first: any, second: any = null) => {
  return condition ? first : second;
};

export const getSortedQuoteListByHippoCarrier = (quoteDataList: Array<any>) => {
  if (isEmpty(quoteDataList)) return [];
  let quoteData: Array<any> = [];
  quoteData = sortBy(quoteDataList, item =>
    item.name.toLowerCase() !== config.prioritizeFloodCarrier.toLowerCase() ? true : false
  );
  return quoteData;
};

export const validatePremiumValue = (premium: number) => {
  return premium > PREMIUM_VALIDATION_DETAILS.MAXIMUM_PREMIUM_VALUE
    ? PREMIUM_VALIDATION_DETAILS.ERROR_MESSAGE
    : '';
};

// Convert the number to currency with commas and decimal
export const formatNumberToCurrencyWithDecimal = (number: number) => {
  if (isNumeric(number) || isNumber(number)) {
    const premiumData = Number(number)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      .replace(/^/, '$');
    if (parseInt(premiumData.split('.')[1]) > 0) return premiumData;
    return premiumData.split('.')[0];
  } else {
    return '';
  }
};

export const getLobHerdId = (lob: string) => {
  let herdId: string = '';
  const {
    home,
    quote,
    quakeDetails: earthquakeDetails,
    petDetails,
    floodDetails,
    homeWarrantyDetails,
    lifeDetails,
    cyberSecurityDetails,
    umbrellaDetails,
  } = store.getState();
  if (!isEmpty(lob)) {
    lob = lob?.toLowerCase() === EARTH_QUAKE_LOB ? INSURANCE_PRODUCTS[3] : lob?.toLowerCase();
    switch (lob) {
      case INSURANCE_PRODUCTS[0]:
        herdId = home?.herdId || home?.herd_id || '';
        break;
      case INSURANCE_PRODUCTS[1]:
        herdId = quote?.herdId || quote?.herd_id || '';
        break;
      case INSURANCE_PRODUCTS[2]:
        herdId = floodDetails?.herdId || '';
        break;
      case INSURANCE_PRODUCTS[3]:
        herdId = earthquakeDetails?.herdId || earthquakeDetails?.herd_id || '';
        break;
      case INSURANCE_PRODUCTS[4]:
        herdId = petDetails?.herdId || petDetails?.herd_id || '';
        break;
      case INSURANCE_PRODUCTS[5]:
        herdId = homeWarrantyDetails?.herdId || homeWarrantyDetails?.herd_id || '';
        break;
      case INSURANCE_PRODUCTS[6]:
        herdId = lifeDetails?.herdId || lifeDetails?.herd_id || '';
        break;
      case INSURANCE_PRODUCTS[7]:
        herdId = cyberSecurityDetails?.herdId || cyberSecurityDetails?.herd_id || '';
        break;
      case INSURANCE_PRODUCTS[8]:
        herdId = umbrellaDetails?.herdId || '';
        break;
    }
  }
  return herdId;
};

export const setAnnexInitialQuestion = (details: any) => {
  const mappedDetails = {
    foundationType: details?.annexRequiredPrefill?.structureType || '',
    propertyType: details?.annexRequiredPrefill?.product || '',
    stories: details?.annexRequiredPrefill?.stories || 0,
    foundationOptions: [...FOUNDATION_TYPES],
  };

  let { foundationType } = mappedDetails;
  let currentQuestion: any = {};

  if (mappedDetails.stories === 0) {
    currentQuestion = {
      ...get(QuestionList, 'stories'),
    };
  } else if (isEmpty(foundationType)) {
    const question = get(QuestionList, foundationType, QuestionList.foundationType);
    const currentOptions = isEmpty(foundationType)
      ? get(mappedDetails, 'foundationOptions', [])
      : get(question, 'options', []);
    currentQuestion = {
      ...question,
      options: [...currentOptions],
    };
  } else {
    foundationType =
      foundationType.toLowerCase() === SLAB && details?.annexRequiredPrefill?.isFloodInfo
        ? 'stories'
        : foundationType;
    const question = get(QuestionList, foundationType);
    currentQuestion = {
      ...question,
    };
  }
  if (
    details?.annexRequiredPrefill?.structureType?.toLowerCase() === SLAB &&
    details?.annexRequiredPrefill?.isFloodInfo
  ) {
    currentQuestion.value = details?.annexRequiredPrefill?.stories || 1;
  }
  store.dispatch(
    setPrefillData({
      ...mappedDetails,
      currentQuestion,
    })
  );
};

export const storeHerdId = (lob: string, herdId: string) => {
  if (!isEmpty(lob) && !isEmpty(herdId)) {
    lob = lob?.toLowerCase() === EARTH_QUAKE_LOB ? INSURANCE_PRODUCTS[3] : lob?.toLowerCase();
    switch (lob) {
      case INSURANCE_PRODUCTS[0]:
        store.dispatch(
          setHomeState({
            herd_id: herdId,
          })
        );
        break;
      case INSURANCE_PRODUCTS[1]:
        store.dispatch(
          setQuoteCompleted({
            herdId: herdId,
          })
        );
        break;
      case INSURANCE_PRODUCTS[2]:
        store.dispatch(setFloodHerdId(herdId));
        break;
      case INSURANCE_PRODUCTS[3]:
        store.dispatch(setQuakeHerdId(herdId));
        break;
      case INSURANCE_PRODUCTS[8]:
        store.dispatch(setUmbrellaHerdId(herdId));
        break;
    }
  }
};

export const getOrgDetails = () => {
  const { common, home } = store.getState();
  let orgDetails: any = {
    organizationCode:
      home?.pconfig?.dale_config?.code ||
      home?.code ||
      common?.pconfig?.pconfig?.dale_config?.code ||
      common?.pconfig?.dale_config?.code ||
      '',
    organizationId:
      home?.pconfig?.dale_config?.id ||
      home?.id ||
      common?.pconfig?.pconfig?.dale_config?.id ||
      common?.pconfig?.dale_config?.id ||
      '',
  };
  return orgDetails;
};

export const amsProductList: array<string> = ['HO3', 'HO4', 'HO5', 'HO6', 'DP1', 'DP3'];

export const getApplicantLobKey = (
  redirectionStep?: string,
  appointmentsKeys?: Array<any>
): string => {
  return redirectionStep === STEPPER_KEYS_MAPPING.PET_INFO
    ? INSURANCE_PRODUCTS[4]
    : getNestedTernaryData(
        redirectionStep === STEPPER_KEYS_MAPPING.LIFE_INFO,
        INSURANCE_PRODUCTS[6],
        getNestedTernaryData(
          redirectionStep === STEPPER_KEYS_MAPPING.UMBRELLA_INFO,
          INSURANCE_PRODUCTS[8],
          getNestedTernaryData(
            appointmentsKeys.includes('PET'),
            INSURANCE_PRODUCTS[4],
            getNestedTernaryData(
              appointmentsKeys.includes('LIFE'),
              INSURANCE_PRODUCTS[6],
              getNestedTernaryData(appointmentsKeys.includes('UMBRELLA'), INSURANCE_PRODUCTS[8], '')
            )
          )
        )
      );
};

export const setIsBindRequested = (carrierId: string, lob: string) => {
  const {
    home,
    floodDetails,
    quote,
    quakeDetails,
    lifeDetails,
    cyberSecurityDetails,
    homeWarrantyDetails,
    umbrellaDetails,
    petDetails,
  } = store.getState();
  let quoteList: Array<any> = [];
  lob = !isEmpty(lob) ? lob?.toLowerCase() : '';
  let lobQuote: any =
    lob === INSURANCE_PRODUCTS[0]
      ? home
      : lob === INSURANCE_PRODUCTS[1]
      ? quote
      : lob === INSURANCE_PRODUCTS[2]
      ? floodDetails
      : lob === INSURANCE_PRODUCTS[3] || lob === EARTH_QUAKE_LOB
      ? quakeDetails
      : lob === INSURANCE_PRODUCTS[4]
      ? petDetails
      : lob === INSURANCE_PRODUCTS[5]
      ? homeWarrantyDetails
      : lob === INSURANCE_PRODUCTS[6]
      ? lifeDetails
      : lob === INSURANCE_PRODUCTS[7]
      ? cyberSecurityDetails
      : lob === INSURANCE_PRODUCTS[8]
      ? umbrellaDetails
      : null;
  if (!!lobQuote) {
    quoteList = lobQuote?.quoteList || [];
  }
  let updatedList: Array<any> = quoteList;
  if (!isEmpty(quoteList)) {
    quoteList?.forEach?.((item: any, index: number) => {
      if (item?.carrier_id?.toString() === carrierId?.toString()) {
        updatedList[index].isBindRequested = true;
      }
    });
  }
  storeUpdatedQuoteListOfLob(updatedList, lob);
};

export const storeUpdatedQuoteListOfLob = (list: array<any>, lob: string) => {
  if (!isEmpty(lob) && !isEmpty(list)) {
    lob = lob?.toLowerCase() === EARTH_QUAKE_LOB ? INSURANCE_PRODUCTS[3] : lob?.toLowerCase();
    let updatedQuotelist: any = {
      quoteList: [...list],
    };
    switch (lob) {
      case INSURANCE_PRODUCTS[0]:
        store.dispatch(
          setHomeState({
            ...updatedQuotelist,
          })
        );
        break;
      case INSURANCE_PRODUCTS[1]:
        store.dispatch(
          setQuoteCompleted({
            ...updatedQuotelist,
          })
        );
        break;
      case INSURANCE_PRODUCTS[2]:
        store.dispatch(
          setPrefillData({
            ...updatedQuotelist,
          })
        );
        break;
      case INSURANCE_PRODUCTS[3]:
        store.dispatch(
          setQuakeQuotes({
            ...updatedQuotelist,
          })
        );
        break;
      case INSURANCE_PRODUCTS[4]:
        store.dispatch(
          setPetQuotes({
            ...updatedQuotelist,
          })
        );
        break;
      case INSURANCE_PRODUCTS[5]:
        store.dispatch(
          storeHomeWarrantyQuoteList({
            ...updatedQuotelist,
          })
        );
        break;
      case INSURANCE_PRODUCTS[6]:
        store.dispatch(
          storeLifeQuoteDetails({
            ...updatedQuotelist,
          })
        );
        break;
      case INSURANCE_PRODUCTS[7]:
        store.dispatch(
          storeCyberSecurityQuoteDetails({
            ...updatedQuotelist,
          })
        );
        break;
      case INSURANCE_PRODUCTS[8]:
        store.dispatch(
          storeUmbrellaQuoteDetails({
            ...updatedQuotelist,
          })
        );
        break;
    }
  }
};

export const getApplicantDetails = () => {
  const { applicant } = store.getState();
  return applicant;
};

export const adhocLobMapping = {
  home: {
    key: 'home',
    code: 'HOME',
  },
  auto: {
    key: 'auto',
    code: 'AUTOP',
  },
  flood: {
    key: 'flood',
    code: 'FLOOD',
  },
  quake: {
    key: 'quake',
    code: 'EQ',
  },
  pet: {
    key: 'pet',
    code: 'PET',
  },
  homewarranty: {
    key: 'homewarranty',
    code: 'HOMEWARRANTY',
  },
  life: {
    key: 'life',
    code: 'LIFE',
  },
  cybersecurity: {
    key: 'cybersecurity',
    code: 'CYBERSECURITY',
  },
  umbrella: {
    key: 'umbrella',
    code: 'PUMBR',
  },
};

export const getAmsBoundedDetailSList = (boundedDetails: any) => {
  let list: Array<any> = [];
  const { assistedByName, customId, loanNumber, salesforceCustomerUUID } =
    !!boundedDetails && boundedDetails;
  if (!isEmpty(customId)) {
    list.push({
      label: CUSTOM_ID.LABEL,
      value: customId,
    });
  }
  if (!isEmpty(loanNumber)) {
    list.push({
      label: LOAN_NUMBER_LABEL,
      value: loanNumber,
    });
  }
  if (!isEmpty(assistedByName)) {
    list.push({
      label: ASSISTED_BY.LABEL,
      value: assistedByName,
    });
  }
  if (!isEmpty(salesforceCustomerUUID)) {
    list.push({
      label: SALESFORCE_CUSTOMER_ID.LABEL,
      value: salesforceCustomerUUID,
    });
  }
  return list;
};

export const getMappedQuotingCarriers = (
  quotingCarriers: Array<any>,
  quoteList: Array<any>,
  errorQuoteList: Array<any>,
  displayWithErrors: boolean
) => {
  const quotesListOfLob = displayWithErrors ? [...quoteList, ...errorQuoteList] : quoteList;
  let tempQuotingCarriers = [...quotingCarriers];
  quotesListOfLob.forEach(quote => {
    if (!isEmpty(quote?.carrier_id?.toString())) {
      tempQuotingCarriers = tempQuotingCarriers.filter(
        qt => qt.carrierId.toString() !== quote.carrier_id.toString()
      );
    } else if (!isEmpty(quote?.CarrierId?.toString())) {
      tempQuotingCarriers = tempQuotingCarriers.filter(
        qt => qt.carrierId.toString() !== quote.CarrierId.toString()
      );
    }
  });
  if (!displayWithErrors) {
    tempQuotingCarriers = tempQuotingCarriers.filter(
      qt =>
        errorQuoteList.filter(quote => quote?.CarrierId?.toString() === qt.carrierId.toString())
          .length <= 0
    );
  } else {
    if (checkPrefillErrorExistInErrorQuoteList(errorQuoteList)) {
      tempQuotingCarriers = [];
    }
  }
  return [
    ...quotesListOfLob,
    ...tempQuotingCarriers.map(quote => ({
      Status: startCase(FAILED),
      Carrier: quote.carrierName,
      tooltip: '',
      logo_url: quote.carrierLogo,
      CarrierId: quote.carrierId,
      StatusText: CARRIER_FAILED_TO_RESPOND_ERROR_MESSAGE,
    })),
  ];
};

export const checkPrefillErrorExistInErrorQuoteList = (errorQuoteList: Array<any>) => {
  let errorList: Array<any> = [];
  errorList = errorQuoteList.filter(
    quote =>
      quote?.CarrierId === PREFILL_ERROR_CARRIER_ID || quote?.carrierId === PREFILL_ERROR_CARRIER_ID || 
      (!isEmpty(quote?.Carrier) && quote?.Carrier?.toLowerCase() === EZLYNX)
  );
  return errorList.length > 0;
};

export const validateEmail = (email: string | null | unknown, isRequired: boolean) => {
  return getNestedTernaryData(
    isEmpty(email?.trim()) && isRequired,
    REQUIRED,
    getNestedTernaryData(!isEmpty(email) && !emailRegex.test(email?.trim()), INVALID, '')
  );
};

export const getStepperTabLength = (appointmentList: Array<any>) => {
  // Here, tab length 2 is used for an applicant and rates steps
  let tabLength = 2;
  INSURANCE_PRODUCTS.forEach(appointment => {
    if (appointmentList.includes(appointment.toUpperCase())) {
      tabLength += getNestedTernaryData(
        appointment === INSURANCE_PRODUCTS[1],
        3,
        getNestedTernaryData(
          appointment === INSURANCE_PRODUCTS[2] ||
            appointment === INSURANCE_PRODUCTS[4] ||
            appointment === INSURANCE_PRODUCTS[6] ||
            appointment === INSURANCE_PRODUCTS[8],
          1,
          0
        )
      );
    }
  });
  return tabLength;
};

export const getAmsDetails = () => {
  const {
    home: { pconfig },
    partner: { isProviderOneLogin, isProviderAms },
  } = store.getState();
  let amsEnabled: boolean = false;
  if (
    !!pconfig?.dale_config?.ams_enabled &&
    ((!!pconfig?.dale_config?.ams_for_salesforce_enabled && inIframe() && isProviderOneLogin) ||
      (!!pconfig?.dale_config?.ams_for_gator_enabled &&
        ((!!isProviderAms && !isProviderOneLogin) || (!!isProviderOneLogin && !inIframe()))))
  ) {
    amsEnabled = true;
  }
  return {
    isAmsEnabled: amsEnabled,
    isAdhocBindEnabled: !!pconfig?.dale_config?.adhoc_bind_enabled,
  };
};

export const getIsCarrierTimeOutSettingEnabled = (businessOption?: string, pconfig?: any) => {
  const isHippoQuoteOnly: boolean =
    !!pconfig?.dale_config?.hippo_only &&
    !isEmpty(businessOption) &&
    businessOption?.toLowerCase() === INSURANCE_PRODUCTS[0];
  return !isHippoQuoteOnly && !!pconfig?.dale_config?.carrier_timeout_message_enabled;
};

export const getObjectValues = (details: any) => {
  return !isEmpty(details) ? Object?.values(details) : [];
};

export const clearReduxStateOnViewQuote = () => {
  let stateData: any = {
    quotingCarriers: [],
    upcomingCarrierList: [],
    quoteStatus: '',
  };
  store.dispatch(
    setHomeState({
      ...stateData,
      previousAddress: null,
      isPreviousAddressAlreadyValidated: false,
    })
  );
  store.dispatch(
    setQuoteCompleted({
      ...stateData,
    })
  );
  store.dispatch(
    setFloodDetails({
      ...stateData,
    })
  );
  store.dispatch(
    storeQuakeDetails({
      ...stateData,
    })
  );
  store.dispatch(
    storeLifeQuoteDetails({
      ...stateData,
    })
  );
  store.dispatch(
    storePetDetails({
      ...stateData,
    })
  );
  store.dispatch(
    storeCyberSecurityQuoteDetails({
      ...stateData,
    })
  );
  store.dispatch(
    storeUmbrellaQuoteDetails({
      ...stateData,
    })
  );
  store.dispatch(
    storeHomeWarrantyQuoteList({
      ...stateData,
    })
  );
};

export const getIsLobQuotesLoading = (lob: string) => {
  let isLoading: boolean = false;
  const {
    home,
    quote,
    quakeDetails: earthquakeDetails,
    petDetails,
    floodDetails,
    homeWarrantyDetails,
    lifeDetails,
    cyberSecurityDetails,
    umbrellaDetails,
  } = store.getState();
  if (!isEmpty(lob)) {
    lob = lob?.toLowerCase() === EARTH_QUAKE_LOB ? INSURANCE_PRODUCTS[3] : lob?.toLowerCase();
    switch (lob) {
      case INSURANCE_PRODUCTS[0]:
        isLoading = !home?.hippoQuoteLoaded || !home?.ezlynxQuotesLoaded;
        break;
      case INSURANCE_PRODUCTS[1]:
        isLoading = quote?.quotesLoading;
        break;
      case INSURANCE_PRODUCTS[2]:
        isLoading = floodDetails?.submitLoader;
        break;
      case INSURANCE_PRODUCTS[3]:
        isLoading = earthquakeDetails?.quakeSubmitLoader;
        break;
      case INSURANCE_PRODUCTS[4]:
        isLoading = petDetails?.quoteListLoader || petDetails?.petBreedsDetailsLoader;
        break;
      case INSURANCE_PRODUCTS[5]:
        isLoading = homeWarrantyDetails?.quoteListLoader;
        break;
      case INSURANCE_PRODUCTS[6]:
        isLoading = lifeDetails?.quoteListLoader;
        break;
      case INSURANCE_PRODUCTS[7]:
        isLoading = cyberSecurityDetails?.quoteListLoader;
        break;
      case INSURANCE_PRODUCTS[8]:
        isLoading = umbrellaDetails?.quoteListLoader;
        break;
    }
  }
  return isLoading;
};

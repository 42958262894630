import { get, isEmpty } from 'lodash';
import { setPrefillData } from '../../pages/redux/actions';
import store from '../../pages/redux/store';
import QuestionList from '../../constants/flood-questionnaire';
import { ENCLOSURE_SQ_FT, FOUNDATION_TYPES, HO3, SLAB, STORIES } from '../../constants';
import { api, FoundationType, Stories } from '../../graphql/generated';
import {
  getFloodQuestionnairDetailsFromPrefill,
  getFoundationTypeMapper,
  getStoriesMapper,
} from './property-info';
import { LineOfBusiness } from '../enums';

export const createFloodQuestionnaire = (type?: FoundationType) => {
  const {
    applicant: {
      personal_details: { product = '' },
    },
    floodDetails,
  } = store.getState();
  const floodQuestionnaireAdditionalData: any =
    floodDetails?.annexPrefill?.floodQuestionnaireAdditionalData ?? {};
  const { foundationType, noOfStories } = getFloodQuestionnairDetailsFromPrefill();
  const stories = noOfStories;
  const propertyType = product || HO3?.toLowerCase();
  const isFloodInfo = !!floodDetails?.isFloodInfoRequired;
  const mappedDetails = {
    foundationType: type ? getFoundationTypeMapper[type] : foundationType,
    propertyType,
    stories,
    foundationOptions: [...FOUNDATION_TYPES],
  };
  let currentQuestion: any = {};
  // Determine current question based on conditions
  if (!isEmpty(mappedDetails.foundationType)) {
    const selectedfoundationType =
      mappedDetails.foundationType.toLowerCase() === SLAB && isFloodInfo
        ? STORIES
        : mappedDetails.foundationType;
    const question = get(QuestionList, selectedfoundationType, QuestionList.foundationType);
    const currentOptions = isEmpty(selectedfoundationType)
      ? mappedDetails.foundationOptions
      : get(question, 'options', []);
    currentQuestion = {
      ...question,
      options: [...currentOptions],
    };
    if (selectedfoundationType?.toLowerCase() === SLAB) {
      currentQuestion.value = mappedDetails.stories;
      currentQuestion.submit = true;
    }
  } else {
    currentQuestion = get(QuestionList, STORIES, {});
  }
  if (!isEmpty(floodQuestionnaireAdditionalData)) {
    if (foundationType === STORIES) {
      currentQuestion.value = floodQuestionnaireAdditionalData.stories || stories || 1;
    }
    if (currentQuestion?.linkQuestion?.name === ENCLOSURE_SQ_FT) {
      currentQuestion.linkQuestion.value = floodQuestionnaireAdditionalData.enclosureSqft;
    }
    currentQuestion.value = floodQuestionnaireAdditionalData.additionalStructureType || '';
  }
  store.dispatch(
    setPrefillData({
      ...mappedDetails,
      currentQuestion,
    })
  );
};

export const getIsFloodInfoRequired = (foundationTypeData?: FoundationType): boolean => {
  const {
    propertyInfoDetails: { propertyInfo },
  } = store.getState();
  const {
    Basement,
    Crawlspace,
    DeepPilings,
    ElevatedPostOrPierAndBeam,
    PierAndGradeBeam,
    ShallowBasement,
    StiltsWithSweepAwayWalls,
  } = FoundationType;
  const floodQuestionnaireList = [
    Basement,
    Crawlspace,
    DeepPilings,
    ElevatedPostOrPierAndBeam,
    PierAndGradeBeam,
    ShallowBasement,
    StiltsWithSweepAwayWalls,
  ];
  if (foundationTypeData && floodQuestionnaireList.includes(foundationTypeData)) {
    return true;
  }
  const foundationType = propertyInfo?.building?.foundation?.type;
  if (!foundationType) {
    return false;
  }
  return floodQuestionnaireList.includes(foundationType);
};

export const getAnnexDetails = (lob?: string) => {
  const {
    applicant,
    floodDetails,
    home: { address, personal_details, productType },
    propertyInfoDetails: { coverageDetails, deductibleDetails, propertyInfo, propertyOtherDetails },
  } = store.getState();
  const { data: { organization } = {} } = api.endpoints.organization.select()(store.getState());
  const { building, rebuildingCost, site } = propertyInfo || {};
  const personalDetails = applicant?.personal_details ?? personal_details ?? {};
  const addressDetails: any = applicant?.address ?? address ?? {};
  const firstName = personalDetails?.first_name ?? '';
  const lastName = personalDetails?.last_name ?? '';
  const middleName = personalDetails?.middle_name ?? '';
  const applicantAddress = {
    addressLine1: addressDetails?.addressLine1 ?? '',
    addressLine2: addressDetails?.addressLine2 ?? '',
    city: addressDetails?.city ?? '',
    country: addressDetails?.country ?? '',
    county: addressDetails?.county ?? '',
    state: addressDetails?.state ?? '',
    street: addressDetails?.street ?? '',
    unit: addressDetails?.unit ?? '',
    validated: !!addressDetails?.validated,
    zip: addressDetails?.zip ?? '',
  };
  const deductible = deductibleDetails?.base ?? personalDetails?.preferredDeductible ?? 1000;
  return {
    authToken: organization?.authToken ?? '',
    product: productType ?? '',
    personalInformation: {
      product: productType ?? '',
      dob: personalDetails?.date_of_birth ?? '',
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      email: personalDetails?.email ?? '',
      phone: personalDetails?.phone_number ?? '',
      address: applicantAddress,
      industry: personalDetails?.industry ?? '',
      is_condo: !!productType && productType?.toUpperCase() === HO3,
      education: personalDetails?.education ?? '',
      occupation: personalDetails?.occupation ?? '',
    },
    stories: getStoriesMapper[building?.general?.stories ?? Stories.One],
    yearBuilt: building?.general?.yearBuilt ?? undefined,
    occupancyType: productType ?? '',
    deductible: deductible,
    covA: coverageDetails?.coverageA,
    covB: coverageDetails?.coverageB,
    covC: coverageDetails?.coverageC,
    covD: coverageDetails?.coverageD,
    covE: coverageDetails?.coverageF,
    covF: coverageDetails?.coverageF,
    swimmingPool: !!site?.swimmingPool?.isFenced,
    squareFootage: building?.general?.squareFootage ?? undefined,
    structureType: building?.foundation?.type ?? '',
    rebuildingCost: rebuildingCost?.hippo?.medium,
    address: applicantAddress,
    lob: lob ?? LineOfBusiness.FLOOD,
    insured: {
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
    },
    isFloodInfo: floodDetails?.isFloodInfoRequired,
    residenceType: propertyOtherDetails?.residenceType?.toUpperCase(),
    personalPropertyReplacementCost: propertyOtherDetails?.personalPropertyReplacementCost,
    previousPolicyCancelled: propertyOtherDetails?.previousPolicyCancelled,
  };
};

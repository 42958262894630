import {
  api,
  CreatePropertyDataMutation,
  MutationCreatePropertyDataArgs,
  MutationUpdatePropertyArgs,
  PropertyAdjustmentsQuery,
  PropertyAdjustmentsQueryVariables,
  PropertyData,
  PropertyDataQuery,
  PropertyDataQueryVariables,
  UpdatePropertyMutation,
} from '../../../../graphql/generated';
import { setAnnexPrefill, setFloodInfoRequired } from '../../../../pages/redux/actions';
import { ERRORS } from '../../../constants';
import { createFloodQuestionnaire, extractGraphQlErrorMessage, getAnnexDetails, getIsFloodInfoRequired, getPrefillStatus } from '../../../utils';
import {
  setCoverageDetails,
  setCreatePropertyInfoError,
  setCreatePropertyInfoLoader,
  setDeductibleDetails,
  setGetPropertyInfoLoader,
  setPrefillStatus,
  setPropertyDetails,
  setUpdatePropertyError,
  setUpdatePropertyInfoLoader,
} from '../../slices/property-info-details.slice';
import { APILifeCycle, GraphqlError } from '../types';
import { resetHomeQuoteDetails } from '../../slices';

const onCreatePropertyData = async (
  request: MutationCreatePropertyDataArgs,
  { dispatch, queryFulfilled }: APILifeCycle<CreatePropertyDataMutation>
) => {
  dispatch(setCreatePropertyInfoLoader(true));
  dispatch(setCreatePropertyInfoError(''));
  try {
    const { data } = await queryFulfilled;
    const propertyDetails = data?.createPropertyData as PropertyData;
    if (propertyDetails) {
      await dispatch(setPropertyDetails(propertyDetails));
    }
    const annexData = await getAnnexDetails();
    const prefillStatus = getPrefillStatus(propertyDetails);
    dispatch(setPrefillStatus(prefillStatus));
    await dispatch(setAnnexPrefill(annexData || {}));
    if (propertyDetails?.building?.foundation?.type) {
      createFloodQuestionnaire(propertyDetails.building.foundation.type);
      dispatch(
        setFloodInfoRequired(getIsFloodInfoRequired(propertyDetails.building.foundation.type))
      );
    }
  } catch (err) {
    dispatch(setCreatePropertyInfoError(ERRORS.SOMETHING_WENT_WRONG_PLS_TRY_AGAIN));
  } finally {
    dispatch(setCreatePropertyInfoLoader(false));
  }
};

const onGetPropertyData = async (
  request: PropertyDataQueryVariables,
  { dispatch, queryFulfilled }: APILifeCycle<PropertyDataQuery>
) => {
  dispatch(setGetPropertyInfoLoader(true));
  try {
    const { data } = await queryFulfilled;
    const propertyData = data.propertyData || {};
    dispatch(setPropertyDetails(propertyData));
    const annexData = getAnnexDetails();
    dispatch(setAnnexPrefill(annexData || {}));
    const prefillStatus = getPrefillStatus(propertyData);
    dispatch(setPrefillStatus(prefillStatus));
    if (propertyData?.building?.foundation?.type) {
      createFloodQuestionnaire(propertyData?.building?.foundation?.type);
      dispatch(
        setFloodInfoRequired(getIsFloodInfoRequired(propertyData?.building?.foundation?.type))
      );
    }
  } catch (err) {
    const error = err as GraphqlError;
    const message = extractGraphQlErrorMessage(error);
    console.log(message)
  }
  dispatch(setGetPropertyInfoLoader(false));
};

const onUpdatePropertyInfo = async (
  request: MutationUpdatePropertyArgs,
  { dispatch, queryFulfilled }: APILifeCycle<UpdatePropertyMutation>
) => {
  dispatch(setUpdatePropertyInfoLoader(true));
  dispatch(setUpdatePropertyError(''));
  try {
    const { data } = await queryFulfilled;
    const updatedPropertyDetails = data.updateProperty || {};
    dispatch(setPropertyDetails(updatedPropertyDetails));
    dispatch(resetHomeQuoteDetails());
    const annexData: any = getAnnexDetails();
    annexData.floodQuestionnaireAdditionalData = null;
    dispatch(setAnnexPrefill(annexData || {}));
    if (updatedPropertyDetails?.building?.foundation?.type) {
      createFloodQuestionnaire(updatedPropertyDetails?.building?.foundation?.type);
      dispatch(
        setFloodInfoRequired(
          getIsFloodInfoRequired(updatedPropertyDetails?.building?.foundation?.type)
        )
      );
    }
  } catch (err) {
    const error = err as GraphqlError;
    const message = extractGraphQlErrorMessage(error);
    dispatch(setUpdatePropertyError(message));
  }
  dispatch(setUpdatePropertyInfoLoader(false));
};

const onPropertyAdjustments = async (
  request: PropertyAdjustmentsQueryVariables,
  { dispatch, queryFulfilled }: APILifeCycle<PropertyAdjustmentsQuery>
) => {
  dispatch(setGetPropertyInfoLoader(true));
  try {
    const { data } = await queryFulfilled;
    const { coverage, deductible } = data?.propertyAdjustments || {};
    dispatch(setCoverageDetails(coverage));
    dispatch(setDeductibleDetails(deductible));
  } catch (err) {
    const error = err as GraphqlError;
    const message = extractGraphQlErrorMessage(error);
    console.log(message)
  }
  dispatch(setGetPropertyInfoLoader(false));
};

const propertyInfoDetailsApi = api.enhanceEndpoints({
  endpoints: {
    createPropertyData: {
      onQueryStarted: onCreatePropertyData,
    },
    propertyData: {
      onQueryStarted: onGetPropertyData,
    },
    updateProperty: {
      onQueryStarted: onUpdatePropertyInfo,
    },
    propertyAdjustments: {
      onQueryStarted: onPropertyAdjustments,
    },
  },
});

export const {
  useCreatePropertyDataMutation,
  usePropertyDataQuery,
  useUpdatePropertyMutation,
  usePropertyAdjustmentsQuery,
} = propertyInfoDetailsApi;
